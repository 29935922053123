import { Box, IconButton, Modal, Stack, Typography, useTheme } from '@mui/material';
import { CommunityPost } from '../../database/community';
import CommentEditor from '../comment/CommentEditor';
import Avatar from '../../profile/info/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import { getConfig } from '../../config';

interface CommunityPostModalProps {
    post: CommunityPost;
    isModalOpen: boolean;
    handleCloseModal: () => void;
    onSave: (post: CommunityPost) => void;
}

const CommunityPostModal: React.FC<CommunityPostModalProps> = ({
    post,
    isModalOpen,
    handleCloseModal,
    onSave,
}) => {
    const theme = useTheme();
    const communityImagesBucket = getConfig().media.communityImagesBucket;

    return (
        <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Stack
                sx={{
                    borderRadius: '16px',
                    borderColor: `${theme.palette.primary.main}`,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    maxHeight: '80%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    backgroundColor: `${theme.palette.background.default}`,
                }}
            >
                <Stack direction='row' padding='10px' alignItems='center'>
                    <Avatar
                        username={post.owner}
                        displayName={post.ownerDisplayName}
                        size={40}
                    />{' '}
                    <Stack paddingLeft='10px'>
                        <Typography variant='body2' fontWeight='bold' marginBottom='0px'>
                            {post.ownerDisplayName}
                        </Typography>
                        <Typography variant='caption'>
                            {new Date(post.createdAt).toLocaleDateString()}
                            {' @ '}
                            {new Date(post.createdAt).toLocaleTimeString([], {
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: true,
                            })}{' '}
                        </Typography>
                    </Stack>
                    <IconButton
                        onClick={handleCloseModal}
                        sx={{
                            marginLeft: 'auto',
                            color: `${theme.palette.secondary.main}`,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <Box
                    sx={{
                        overflow: 'auto',
                    }}
                >
                    <Box sx={{ padding: '10px', paddingTop: '0px' }}>
                        <div
                            style={{
                                marginBottom: '10px',
                            }}
                        >
                            <Typography
                                variant='body2'
                                sx={{
                                    padding: '5px',
                                    paddingTop: '0px',
                                    overflowWrap: 'break-word',
                                }}
                            >
                                {post.content}
                            </Typography>
                            {(post.containsMedia) && (
                                <img
                                    src={post.previewUrl ? post.previewUrl : `${communityImagesBucket}/${post.owner}/${post.createdAt}`}
                                    style={{
                                        width: '100%',
                                        objectFit: 'cover',
                                        maxHeight: '600px',
                                        borderRadius: '16px',
                                    }}
                                    alt={`${post.ownerDisplayName}'s post`}
                                />
                            )}
                        </div>
                        {post.comments.length > 0 &&
                            post.comments.map((comment, i) => (
                                <Box
                                    key={comment.owner + comment.createdAt}
                                    sx={{
                                        padding: '5px',
                                        background: `${theme.palette.secondary.light}`,
                                        borderRadius: '16px',
                                        mb:
                                            post.comments.length - 1 === i
                                                ? '0px'
                                                : '20px',
                                    }}
                                >
                                    <Stack direction='row' spacing={1}>
                                        <Avatar
                                            username={post.comments[i].owner}
                                            displayName={
                                                post.comments[i].ownerDisplayName
                                            }
                                            size={40}
                                        />
                                        <Stack direction='column'>
                                            <Typography variant='body2' fontWeight='bold'>
                                                {post.comments[i].ownerDisplayName}{' '}
                                            </Typography>
                                            <Typography variant='caption'>
                                                {new Date(
                                                    post.comments[i].createdAt,
                                                ).toLocaleDateString()}
                                                {' @ '}
                                                {new Date(
                                                    post.comments[i].createdAt,
                                                ).toLocaleTimeString([], {
                                                    hour: 'numeric',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                })}
                                            </Typography>
                                        </Stack>
                                    </Stack>

                                    <Typography
                                        variant='body2'
                                        sx={{
                                            overflowWrap: 'break-word',
                                            padding: '4px',
                                        }}
                                    >
                                        {post.comments[i].content}
                                    </Typography>
                                </Box>
                            ))}
                    </Box>
                </Box>
                <div style={{ padding: '10px' }}>
                    <CommentEditor post={post} onSave={onSave} />
                </div>
            </Stack>
        </Modal>
    );
};
export default CommunityPostModal;
