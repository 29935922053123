import axios, { AxiosResponse } from 'axios';

import { getConfig } from '../config';
import { Albums, Track } from '../database/music';

const BASE_URL = getConfig().api.baseUrl;

/**
 * EventsApiContextType provides an API for interacting with Bandsintown Events.
 */
export type MusicApiContextType = {
    /**
     * getAlbums returns a 200 OK if the artist's albums are obtained from Spotify
     * or an error otherwise.
     * @returns An AxiosResponse with a list of Spotify albums.
     */
    getAlbums: (includeAlbumGroup: string) => Promise<AxiosResponse<Albums, any>>;

    /**
     * getTopTracks returns a 200 OK if the artist's top tracks are obtained from Spotify
     * or an error otherwise.
     * @returns An AxiosResponse with a list of Spotify top tracks.
     */
    getTopTracks: () => Promise<AxiosResponse<Track[], any>>;

    getTrackById: (id: string) => Promise<AxiosResponse<Track, any>>;
};

/**
 * getAlbums returns a 200 OK if the artist's albums are obtained from Spotify
 * or an error otherwise.
 * @returns An AxiosResponse with a list of Spotify albums.
 */
export function getAlbums(includeAlbumGroup: string) {
    return axios.get<Albums>(BASE_URL + '/public/music/albums/get', {
        params: { includeAlbumGroup },
    });
}

/**
 * getTopTracks returns a 200 OK if the artist's top tracks are obtained from Sptofiy
 * or an error otherwise.
 * @returns An AxiosResponse with a list of Spotify top tracks.
 */
export function getTopTracks() {
    return axios.get<Track[]>(BASE_URL + '/public/music/top-tracks/get');
}

export function getTrackById(id: string) {
    return axios.get<Track>(BASE_URL + '/public/music/track/get', {
        params: { id },
    });
}
