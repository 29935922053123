import axios, { AxiosResponse } from 'axios';
import { getConfig } from '../config';
import { Question } from '../database/theSource';

const BASE_URL = getConfig().api.baseUrl;
export interface TheSourceApiContextType {
    /**
     * Creates a question.
     * @param req The CreateQuestionRequest.
     * @returns The newly created question record.
     */
    createQuestion: (req: CreateQuestionRequest) => Promise<AxiosResponse<Question>>;

    /**
     * listMostRecentQuestions returns a list of most recent questions.
     * @param startKey The optional startKey to use when searching.
     * @returns A list of the most recent questions.
     */
    listMostRecentQuestions: (
        startKey?: string,
    ) => Promise<AxiosResponse<ListQuestionsResponse>>;

    /**
     * listQuestionsByOwner returns a list of the current user's questions.
     * @param startKey The optional startKey to use when searching.
     * @returns A list of the most recent questions.
     */
    listQuestionsByOwner: (
        startKey?: string,
    ) => Promise<AxiosResponse<ListQuestionsResponse>>;

    /**
     * listQuestionsByResponseType returns a list of questions for a specific requested response type.
     * @param startKey The optional startKey to use when searching.
     * @returns A list of the most recent questions.
     */
    listQuestionsByResponseType: (
        responseType: string,
        startKey?: string,
    ) => Promise<AxiosResponse<ListQuestionsResponse>>;

    /**
     * listQuestionsByStatus returns a list of questions for a specific status.
     * @param startKey The optional startKey to use when searching.
     * @returns A list of the most recent questions for the specified status.
     */
    listQuestionsByStatus: (
        status: string,
        scanForwardIndex: boolean,
        startKey?: string,
    ) => Promise<AxiosResponse<ListQuestionsResponse>>;

    /**
     * answerQuestion updates a question with the artist's answer.
     * @param req The AnswerQuestionRequest.
     * @returns The updated question.
     */
    answerQuestion: (req: AnswerQuestionRequest) => Promise<AxiosResponse<Question>>;

    theSourceGeneratePresignedUrl: (
        question: Question,
        contentType: string,
    ) => Promise<AxiosResponse<PresignedUrlResponse>>;

    uploadVideoToS3: (req: MediaUploadRequest) => Promise<AxiosResponse<string>>;

    /**
     * deleteQuestion deletes the provided question in the database.
     * @param question The Question.
     * @returns The deleted question.
     */
        deleteQuestion: (question: Question) => Promise<AxiosResponse<Question, any>>;
}

export interface CreateQuestionRequest {
    content: string;
    responseType: string;
    isAcknowledged: boolean;
}

export interface AnswerQuestionRequest {
    questionOwner: string;
    questionCreatedAt: string;
    content: string;
    responseType: string;
}

export interface ListQuestionsResponse {
    questions: Question[];
    lastEvaluatedKey?: string;
}

export interface PresignedUrlResponse {
    presignedUrl: string;
    key: string;
}

export interface MediaUploadRequest {
    presignedUrl: string;
    contentType: string;
    mediaContent: File;
}

export function createQuestion(idToken: string, req: CreateQuestionRequest) {
    return axios.post<Question>(`${BASE_URL}/the-source/question`, req, {
        headers: {
            Authorization: 'Bearer ' + idToken,
        },
    });
}

export async function listMostRecentQuestions(idToken: string, startKey?: string) {
    return axios.get<ListQuestionsResponse>(BASE_URL + '/the-source/recent', {
        params: { startKey },
        headers: {
            Authorization: 'Bearer ' + idToken,
        },
    });
}

export async function listQuestionsByOwner(idToken: string, startKey?: string) {
    return axios.get<ListQuestionsResponse>(BASE_URL + '/the-source/my-questions', {
        params: { startKey },
        headers: {
            Authorization: 'Bearer ' + idToken,
        },
    });
}

export async function listQuestionsByResponseType(
    responseType: string,
    idToken: string,
    startKey?: string,
) {
    return axios.get<ListQuestionsResponse>(
        BASE_URL + `/the-source/questions/${responseType}`,
        {
            params: { startKey },
            headers: {
                Authorization: 'Bearer ' + idToken,
            },
        },
    );
}

export async function listQuestionsByStatus(
    status: string,
    scanForwardIndex: boolean,
    idToken: string,
    startKey?: string,
) {
    return axios.get<ListQuestionsResponse>(
        BASE_URL + `/the-source/questions/status/${status}`,
        {
            params: { startKey, scanForwardIndex },
            headers: {
                Authorization: 'Bearer ' + idToken,
            },
        },
    );
}

export function answerQuestion(idToken: string, req: AnswerQuestionRequest) {
    return axios.put<Question>(`${BASE_URL}/the-source/question/answer`, req, {
        headers: {
            Authorization: 'Bearer ' + idToken,
        },
    });
}

export function theSourceGeneratePresignedUrl(
    idToken: string,
    question: Question,
    contentType: string,
) {
    return axios.post(
        `${BASE_URL}/the-source/question/presign-url`,
        { question, contentType },
        {
            headers: {
                Authorization: 'Bearer ' + idToken,
            },
        },
    );
}

export function uploadVideoToS3(req: MediaUploadRequest) {
    return axios.put(req.presignedUrl, req.mediaContent, {
        headers: {
            'Content-Type': req.contentType,
        },
    });
}

export function deleteQuestion(idToken: string, question: Question) {
    return axios.delete(`${BASE_URL}/the-source/question/delete/${question.owner}/${question.createdAt}`, {
        headers: {
            Authorization: 'Bearer ' + idToken,
        },
    },)
}