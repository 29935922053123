import { useState } from 'react';
import { Alert, Button, Container, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Avatar from '../info/Avatar';
import { RequestSnackbar, useRequest } from '../../api/Request';
import { useApi } from '../../api/Api';
import { useAuth } from '../../auth/Auth';
import { hasCreatedProfile } from '../../database/user';
import { useNavigate } from 'react-router-dom';
import { useCache } from '../../api/cache/Cache';
import { getConfig } from '../../config';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import SaveIcon from '@mui/icons-material/Save';

export interface ProfileCreatorFormProps { }

export enum ProfilePicAction {
    Update = 'Update',
    Delete = 'Delete',
}

const ProfileEditorPage: React.FC<ProfileCreatorFormProps> = () => {
    const api = useApi();
    const request = useRequest();
    const user = useAuth().user!;
    const navigate = useNavigate();
    const { setImageBypass } = useCache();
    const [profilePicAction, setProfilePicAction] = useState<ProfilePicAction>()
    const [displayName, setDisplayName] = useState(user.displayName);
    const [bio, setBio] = useState(user.bio);
    const [funFact, setFunFact] = useState(user.funFact);
    const [musicId, setMusicId] = useState(user.musicId);
    const [videoId, setVideoId] = useState(user.videoId);
    const [fileToUpload, setFileToUpload] = useState<File>();
    const [textResponseCost, setTextResponseCost] = useState<number>(
        user.artistInfo?.costTextResponseType || 0,
    );
    const [videoResponseCost, setVideoResponseCost] = useState<number>(
        user.artistInfo?.costVideoResponseType || 0,
    );
    const [profilePictureUrl, setProfilePictureUrl] = useState<string>();
    const artistName = getConfig().artist.name
    const profileCreated = hasCreatedProfile(user);
    const canSave = displayName.trim().length > 0;

    const onSave = () => {
        if (!canSave) {
            return;
        }
        request.onStart();
        if (fileToUpload !== undefined) {
            // Generate presigned URL
            api.userGeneratePresignedUrl(fileToUpload.type)
                .then((resp) => {
                    const presignedUrl = resp.data.presignedUrl
                    // Upload media to S3
                    api.uploadProfilePicToS3({
                        presignedUrl: resp.data.presignedUrl,
                        mediaContent: fileToUpload,
                        contentType: fileToUpload.type,
                    })
                        .then(() => {
                            setProfilePictureUrl('');
                            api.updateUser(
                                {
                                    displayName: displayName.trim(),
                                    bio: bio,
                                    funFact: funFact,
                                    musicId: musicId,
                                    videoId: videoId,
                                    hasCreatedProfile: true,
                                    artistInfo: {
                                        stripeAccountId: user.artistInfo
                                            ? user.artistInfo.stripeAccountId
                                            : '',
                                        onboardingComplete: user.artistInfo
                                            ? user.artistInfo.onboardingComplete
                                            : false,
                                        costTextResponseType: textResponseCost,
                                        costVideoResponseType: videoResponseCost,
                                    },
                                    profilePicAction,
                                },
                            )
                                .then(() => {
                                    request.onSuccess('Profile updated');
                                    setImageBypass(Date.now());
                                    navigate('..');
                                })
                                .catch((err) => {
                                    console.error(err);
                                    request.onFailure(err);
                                });
                        })
                        .catch((err: unknown) => {
                            console.error('Unable to upload profile pic: ', err);
                            request.onFailure();
                        });
                })
                .catch((err: unknown) => {
                    console.error('Unable to upload profile pic, signing error: ', err);
                    request.onFailure();
                });
        } else {
            api.updateUser(
                {
                    displayName: displayName.trim(),
                    bio: bio,
                    funFact: funFact,
                    musicId: musicId,
                    videoId: videoId,
                    hasCreatedProfile: true,
                    artistInfo: {
                        stripeAccountId: user.artistInfo
                            ? user.artistInfo.stripeAccountId
                            : '',
                        onboardingComplete: user.artistInfo
                            ? user.artistInfo.onboardingComplete
                            : false,
                        costTextResponseType: textResponseCost,
                        costVideoResponseType: videoResponseCost,
                    },
                    profilePicAction,
                },
            )
                .then(() => {
                    request.onSuccess('Profile updated');
                    navigate('..');
                })
                .catch((err) => {
                    console.error(err);
                    request.onFailure(err);
                });
        }
    };

    const onChangeProfilePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (!file) return;
        if (profilePictureUrl) {
            URL.revokeObjectURL(profilePictureUrl);
            setProfilePictureUrl('');
        }
        setProfilePictureUrl(URL.createObjectURL(file));
        setFileToUpload(file);
        setProfilePicAction(ProfilePicAction.Update);
    };

    const onDeleteProfilePicture = () => {
        setProfilePictureUrl('');
        setFileToUpload(undefined);
        setProfilePicAction(ProfilePicAction.Delete);
    };

    return (
        <Container maxWidth='md' sx={{ pt: 6, mb: '100px' }}>
            {!profileCreated && (
                <Alert severity='warning' sx={{ mt: 2, mb: 2 }}>
                    Finish profile before interacting with {artistName}.
                </Alert>
            )}
            <Stack spacing={5}>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    flexWrap='wrap'
                    rowGap={2}
                >
                    <Typography variant='h4' mr={2}>
                        Edit Profile
                    </Typography>

                    <Stack direction='row' spacing={2}>
                        <LoadingButton
                            variant='contained'
                            onClick={onSave}
                            loading={request.isLoading()}
                            disabled={!canSave}
                            startIcon={<SaveIcon />}
                        >
                            Save
                        </LoadingButton>

                        <Button
                            variant='contained'
                            color='secondary'
                            disableElevation
                            onClick={() => navigate('..')}
                            startIcon={<NotInterestedIcon />}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
                <Stack spacing={4}>
                    <Stack direction='row' alignItems='center' spacing={3}>
                        <Avatar
                            user={user}
                            size={150}
                            url={profilePictureUrl}
                        />
                        <Stack spacing={2} alignItems='start'>
                            <Button
                                component='label'
                                variant='outlined'
                                startIcon={<PhotoLibraryIcon />}
                            >
                                Upload
                                <input
                                    type='file'
                                    accept='image/*'
                                    hidden
                                    onChange={onChangeProfilePicture}
                                />
                            </Button>
                            <Button
                                component='label'
                                variant='outlined'
                                onClick={onDeleteProfilePicture}
                                startIcon={<DeleteIcon />}
                            >
                                Delete
                            </Button>
                        </Stack>
                    </Stack>
                    <TextField
                        required
                        label='Display Name'
                        value={displayName}
                        onChange={(event) => setDisplayName(event.target.value)}
                        helperText={'This is how other users will identify you'}
                    />
                    <TextField
                        label='Bio'
                        multiline
                        minRows={3}
                        maxRows={6}
                        value={bio}
                        onChange={(event) => setBio(event.target.value)}
                        helperText={'Tell us about yourself!'}
                    />
                    {user.isArtist && (
                        <>
                            <TextField
                                label='Fun Fact'
                                multiline
                                minRows={3}
                                maxRows={6}
                                value={funFact}
                                onChange={(event) => setFunFact(event.target.value)}
                            />
                            <TextField
                                label='Music ID'
                                maxRows={1}
                                value={musicId}
                                onChange={(event) => setMusicId(event.target.value)}
                            />
                            <TextField
                                label='Video ID'
                                maxRows={1}
                                value={videoId}
                                onChange={(event) => setVideoId(event.target.value)}
                            />
                            <TextField
                                label='The Source Text Response Cost'
                                maxRows={1}
                                value={textResponseCost}
                                onChange={(event) =>
                                    setTextResponseCost(+event.target.value)
                                }
                            />
                            <TextField
                                label='The Source Video Response Cost'
                                maxRows={1}
                                value={videoResponseCost}
                                onChange={(event) =>
                                    setVideoResponseCost(+event.target.value)
                                }
                            />
                        </>
                    )}
                    <RequestSnackbar request={request} />
                </Stack>
            </Stack>
        </Container>
    );
};

export default ProfileEditorPage;
