import axios, { AxiosResponse } from 'axios';

import { getConfig } from '../config';
import { CommunityPost } from '../database/community';
import { MediaUploadRequest, PresignedUrlResponse } from './theSourceApi';

const BASE_URL = getConfig().api.baseUrl;

export type CommunityApiContextType = {
    /**
     * createCommunityPost saves the provided post in the database.
     * @param req The CommunityPostRequest.
     * @returns The newly created post.
     */
    createCommunityPost: (
        req: CommunityPostRequest,
    ) => Promise<AxiosResponse<CommunityPost, any>>;

    /**
     * deleteCommunityPost deletes the provided post in the database.
     * @param req The CommunityPostRequest.
     * @returns The newly created post.
     */
    deletePost: (post: CommunityPost) => Promise<AxiosResponse<CommunityPost, any>>;

    /**
     * listCommunityPosts returns a list of most recent posts.
     * @param startKey The optional startKey to use when searching.
     * @returns A list of the most recent community posts.
     */
    listCommunityPosts: (
        startKey?: string,
    ) => Promise<AxiosResponse<ListCommunityPostsResponse, any>>;

    /**
     * listCommunityPostsByOwner returns a list of posts for the signed in user.
     * @param startKey The optional startKey to use when searching.
     * @returns A list of community posts for the signed in user.
     */
    listCommunityPostsByOwner: (
        startKey?: string,
    ) => Promise<AxiosResponse<ListCommunityPostsResponse, any>>;

    /**
     * createComment adds the given content as a comment on the given post.
     * @param id The id of the post.
     * @param content The text content of the post.
     */
    createComment: (
        postOwner: string,
        postCreatedAt: string,
        content: string,
    ) => Promise<AxiosResponse<CommunityPost, any>>;

    communityPostGeneratePresignedUrl: (
        post: CommunityPost,
        contentType: string,
    ) => Promise<AxiosResponse<PresignedUrlResponse>>;

    uploadMediaToS3: (req: MediaUploadRequest) => Promise<AxiosResponse<string>>;
};

export interface ListCommunityPostsResponse {
    posts: CommunityPost[];
    lastEvaluatedKey?: string;
}

export interface CommunityPostRequest {
    content: string;
    containsMedia: boolean;
}

export function createCommunityPost(idToken: string, req: CommunityPostRequest) {
    return axios.post<CommunityPost>(BASE_URL + '/community/post/create', req, {
        headers: {
            Authorization: 'Bearer ' + idToken,
        },
    });
}

export function deletePost(idToken: string, post: CommunityPost) {
    return axios.delete<CommunityPost>(
        BASE_URL + `/community/post/delete/${post.owner}/${post.createdAt}`,
        {
            headers: {
                Authorization: 'Bearer ' + idToken,
            },
        },
    );
}

export async function listCommunityPosts(idToken: string, startKey?: string) {
    return axios.get<ListCommunityPostsResponse>(BASE_URL + '/community/recent', {
        params: { startKey },
        headers: {
            Authorization: 'Bearer ' + idToken,
        },
    });
}

export function listCommunityPostsByOwner(idToken: string, startKey?: string) {
    return axios.get<ListCommunityPostsResponse>(BASE_URL + '/community/my-posts', {
        params: { startKey },
        headers: {
            Authorization: 'Bearer ' + idToken,
        },
    });
}

/**
 * createComment adds the given content as a comment on the given post.
 * @param id The id of the post.
 * @param content The text content of the post.
 */
export function createComment(
    idToken: string,
    postOwner: string,
    createdAt: string,
    content: string,
) {
    return axios.post<CommunityPost>(
        BASE_URL + `/post/${postOwner}/${createdAt}/comment`,
        { content },
        {
            headers: {
                Authorization: 'Bearer ' + idToken,
            },
        },
    );
}

export function communityPostGeneratePresignedUrl(
    idToken: string,
    post: CommunityPost,
    contentType: string,
) {
    return axios.post(
        `${BASE_URL}/post/presign-url`,
        { post, contentType },
        {
            headers: {
                Authorization: 'Bearer ' + idToken,
            },
        },
    );
}

export function uploadMediaToS3(req: MediaUploadRequest) {
    return axios.put(req.presignedUrl, req.mediaContent, {
        headers: {
            'Content-Type': req.contentType,
        },
    });
}