import { Chip, Tooltip } from '@mui/material';
import Icon from '../../components/Icon';

interface QuestionTimerChipProps {
    createdAt: string;
}

interface TimerChipDisplay {
    timeRemainingShort: string;
    timeRemainingLong: string;
    color: 'primary' | 'warning';
}
const QuestionTimerChip: React.FC<QuestionTimerChipProps> = ({ createdAt }) => {

    const getChipDisplayProperties = (from: string): TimerChipDisplay => {
        const today = new Date();
        const thresholdDate = today.setDate(today.getDate() - 30)
        const timeRemainingMilliseconds = new Date(from).getTime() - thresholdDate;

        const diffInDays = Math.floor(timeRemainingMilliseconds / (1000 * 60 * 60 * 24));
        const diffInHours = Math.floor((timeRemainingMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        // Display remaining time
        if (diffInDays > 1) {
            return {
                timeRemainingShort: `< ${diffInDays} days`,
                timeRemainingLong: `Less than ${diffInDays} days`,
                color: 'warning'
            }
        } else if (diffInDays === 1) {
            return {
                timeRemainingShort: `< ${diffInDays} day`,
                timeRemainingLong: `Less than ${diffInDays} day`,
                color: 'primary'
            }
        } else if (diffInHours > 1) {
            return {
                timeRemainingShort: `< ${diffInHours} hours`,
                timeRemainingLong: `Less than ${diffInHours} hours`,
                color: 'primary'
            }
        } else { // if (diffInHours <= 1) 
            return {
                timeRemainingShort: `< ${diffInHours} hour`,
                timeRemainingLong: `Less than ${diffInHours} hour`,
                color: 'primary'
            }
        }
    }

    const timerChipDisplay = getChipDisplayProperties(createdAt)

    return (
        <Tooltip title={`Time remaining to answer question: ${timerChipDisplay.timeRemainingLong}`}>
            <Chip
                icon={<Icon name={'timer'} />}
                label={timerChipDisplay.timeRemainingShort}
                variant='outlined'
                color={timerChipDisplay.color}
            />
        </Tooltip>
    );
};

export default QuestionTimerChip;
