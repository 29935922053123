import { AppBar, Container, Toolbar, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import NavbarMenu from './NavbarMenu';

const Navbar = () => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';
    const isTopMenu = useMediaQuery('(min-width:800px)');

    return (
        <AppBar
            sx={{
                zIndex: 1300,
                height: isTopMenu ? '160px' : '80px',
                position: isTopMenu ? 'sticky' : 'fixed',
                top: 'auto',
                bottom: isTopMenu ? 'auto' : 0,
                clipPath: isTopMenu
                    ? isHomePage
                        ? 'none'
                        : 'polygon(0% 0%, 150% 0%, 0% 100%)'
                    : 'none',
                background: isTopMenu ? (isHomePage ? 'transparent' : '') : '',
                // marginTop: 100,
                display: 'flex',
            }}
            elevation={0}
        >
            <Container maxWidth='xl' sx={{ height: 1 }}>
                <Toolbar disableGutters sx={{ height: 1 }}>
                    <NavbarMenu isTopMenu={isTopMenu} />
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Navbar;
