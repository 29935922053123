import { ReactNode } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import { grey, red } from '@mui/material/colors';
import { CssBaseline } from '@mui/material';

const ThemeProvider = ({ children }: { children: ReactNode }) => {
    const theme = createTheme({
        palette: {
            action: {
                disabledBackground: red[900],
                disabled: grey[50],
            },
            primary: {
                main: red[900],
                light: red[500],
            },
            secondary: {
                main: grey[50],
                light: grey[800],
            },
            background: {
                default: grey[900],
                paper: grey[900],
            },
            text: {
                primary: grey[50],
            },
        },
        typography: {
            fontFamily: 'Arial',
            fontSize: 14,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& label': {
                            color: grey[50],
                        },
                        '&:hover label': {
                            color: red[900],
                        },
                        '& label.Mui-focused': {
                            color: grey[50],
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: grey[50],
                        },
                        '& .MuiOutlinedInput-root': {
                            color: grey[50],
                            '& fieldset': {
                                borderColor: grey[50],
                            },
                            '&:hover fieldset': {
                                borderColor: red[900],
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: red[900],
                            },
                        },
                        '& .MuiFormHelperText-root': {
                            color: grey[50],
                        },
                    },
                },
            },
        },
    });

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline enableColorScheme />
            {children}
        </MuiThemeProvider>
    );
};

export default ThemeProvider;
