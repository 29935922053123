import { Stack, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { LoadingButton } from '@mui/lab';

import { Request } from '../api/Request';

interface LoadMoreButtonProps {
    request: Request;
    startKey?: string;
    onLoadMore: () => void;
}

const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({
    request,
    startKey,
    onLoadMore,
}) => {
    if (Object.values(startKey || {}).length > 0) {
        return (
            <Stack alignItems='center' spacing={1}>
                <LoadingButton
                    variant='contained'
                    loading={request.isLoading()}
                    onClick={onLoadMore}
                >
                    Load More
                </LoadingButton>
            </Stack>
        );
    }

    return (
        <Stack alignItems='center' spacing={1}>
            <CheckCircleOutlineIcon color='primary' fontSize='large' />
            <Typography variant='h5' color='white' textAlign='center'>
                You're all caught up
            </Typography>
        </Stack>
    );
};

export default LoadMoreButton;
