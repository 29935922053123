import React, { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
    Button,
    Icon,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import YxngDaviLogo from '../assets/YxngDaviLogo.svg';
import { AuthContextType, AuthStatus, useAuth } from '../auth/Auth';
import ProfileButton from './ProfileButton';
import Person2Icon from '@mui/icons-material/Person2';
import EventIcon from '@mui/icons-material/Event';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import GroupsIcon from '@mui/icons-material/Groups';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MenuIcon from '@mui/icons-material/Menu';
import { QuestionAnswer } from '@mui/icons-material';

const Logo = () => {
    const navigate = useNavigate();
    return (
        <img
            src={YxngDaviLogo}
            style={{
                paddingTop: '10px',
                paddingBottom: '10px',
                maxHeight: '100%',
                marginRight: '15px',
                cursor: 'pointer',
            }}
            alt=''
            onClick={() => navigate('/')}
        />
    );
};

interface MenuProps {
    isTopMenu: boolean;
    auth: AuthContextType;
}

interface NavbarMenuProps {
    isTopMenu: boolean;
}

interface NavbarItem {
    name: string;
    icon: JSX.Element | null;
    onClick: () => void;
}

const NavMenuItem: React.FC<{
    item: NavbarItem;
    handleClick: (func: () => void) => () => void;
}> = ({ item, handleClick }) => {
    return (
        <>
            <MenuItem key={item.name} onClick={handleClick(item.onClick)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <Typography textAlign='center'>{item.name}</Typography>
            </MenuItem>
        </>
    );
};

function useNavbarItems(
    isTopMenu: boolean,
    handleClick: (func: () => void) => () => void,
    auth: AuthContextType,
) {
    const navigate = useNavigate();

    const breakpoints = {
        topMenu: {
            showAll: useMediaQuery('(min-width:1200px)'),
            hide1: useMediaQuery('(min-width: 1150px)'),
            hide2: useMediaQuery('(min-width:950px)'),
            hide3: useMediaQuery('(min-width:850px)'),
            hide4: useMediaQuery('(min-width:700px)'),
            hide5: useMediaQuery('(min-width:700px)'),
        },
        bottomMenu: {
            showAll: useMediaQuery('(min-width:800px)'),
            hide1: useMediaQuery('(min-width:700px)'),
            hide2: useMediaQuery('(min-width:525px)'),
            hide3: useMediaQuery('(min-width:425px)'),
            hide4: useMediaQuery('(min-width:350px)'),
            hide5: useMediaQuery('(min-width:250px)'),
        },
    };

    const { showAll, hide1, hide2, hide3, hide4, hide5 } =
        breakpoints[isTopMenu ? 'topMenu' : 'bottomMenu'];
    const showProfileDropdown = useMediaQuery('(min-width:800px)');

    const startItems = allStartItems(navigate, isTopMenu, auth);

    let startItemCount = 0;
    startItemCount = startItems.length;
    if (showAll) {
        startItemCount = startItems.length;
    } else if (hide1) {
        startItemCount = startItems.length - 1;
    } else if (hide2) {
        startItemCount = startItems.length - 2;
    } else if (hide3) {
        startItemCount = startItems.length - 3;
    } else if (hide4) {
        startItemCount = startItems.length - 4;
    } else if (hide5) {
        startItemCount = startItems.length - 5;
    } else {
        startItemCount = startItems.length - 6;
    }

    const shownStartItems: JSX.Element[] = startItems
        .slice(0, startItemCount)
        .map((item) =>
            isTopMenu ? (
                <TopMenuStartItem key={item.name} item={item} />
            ) : (
                <BottomMenuStartItem key={item.name} item={item} />
            ),
        );

    const menuItems: JSX.Element[] = startItems
        .slice(startItemCount)
        .map((item) => (
            <NavMenuItem key={item.name} item={item} handleClick={handleClick} />
        ));

    const endItems: JSX.Element[] = [];

    if (showProfileDropdown) {
        endItems.push(<ProfileButton key='profileDropdown' />);
    }

    return {
        startItems: shownStartItems,
        menuItems: menuItems,
        endItems: endItems,
    };
}

function allStartItems(
    navigate: NavigateFunction,
    isTopMenu: boolean,
    auth: AuthContextType,
): NavbarItem[] {
    return [
        {
            name: 'Events',
            icon: <EventIcon color='secondary' />,
            onClick: () => navigate('/events'),
        },
        {
            name: 'The Source',
            icon: <QuestionAnswer color='secondary' />,
            onClick: () => navigate('/the-source'),
        },
        {
            name: 'Music',
            icon: <LibraryMusicIcon color='secondary' />,
            onClick: () => navigate('/music'),
        },
        {
            name: 'Media',
            icon: <VideoLibraryIcon color='secondary' />,
            onClick: () => navigate('/videos'),
        },
        {
            name: 'Community',
            icon: <GroupsIcon color='secondary' />,
            onClick: () => navigate('/community'),
        },
        ...(auth.user?.isAdmin
            ? [
                  {
                      name: 'Admin',
                      icon: <AdminPanelSettingsIcon color='secondary' />,
                      onClick: () => navigate('/admin'),
                  },
              ]
            : []),
        ...(!isTopMenu
            ? [
                  {
                      name: 'Profile',
                      icon: <Person2Icon color='secondary' />,
                      onClick: () => navigate('/profile'),
                  },
              ]
            : []),
    ];
}

const BottomMenuStartItem: React.FC<{ item: NavbarItem }> = ({ item }) => {
    return (
        <>
            <Button
                key={item.name}
                color='secondary'
                onClick={item.onClick}
                sx={{ flex: 1 }}
            >
                <Stack alignItems='center' justifyContent='center' spacing={1}>
                    <Icon color='inherit' aria-label={item.name} sx={{ height: '26px' }}>
                        {item.icon}
                    </Icon>
                    <Typography variant='caption'>{item.name}</Typography>
                </Stack>
            </Button>
        </>
    );
};

const TopMenuStartItem: React.FC<{ item: NavbarItem }> = ({ item }) => {
    return (
        <>
            <Button
                key={item.name}
                onClick={item.onClick}
                sx={{ color: 'white', whiteSpace: 'nowrap' }}
                startIcon={item.icon}
            >
                {item.name}
            </Button>
        </>
    );
};

const TopMenu: React.FC<MenuProps> = ({ isTopMenu, auth }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (func: () => void) => {
        return () => {
            func();
            handleClose();
        };
    };

    const { startItems, menuItems, endItems } = useNavbarItems(
        isTopMenu,
        handleClick,
        auth,
    );

    return (
        <>
            <Logo />
            <Stack spacing={3} direction='row' sx={{ flexGrow: 1, marginBottom: '50px' }}>
                {startItems}
                {menuItems.length > 0 && (
                    <>
                        <Tooltip title='More'>
                            <IconButton
                                data-cy='navbar-more-button'
                                onClick={handleOpen}
                                sx={{ color: 'white' }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {menuItems}
                        </Menu>
                    </>
                )}
                {endItems}
            </Stack>
        </>
    );
};

const BottomMenu: React.FC<MenuProps> = ({ isTopMenu, auth }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (func: () => void) => {
        return () => {
            func();
            handleClose();
        };
    };

    const { startItems, menuItems, endItems } = useNavbarItems(
        isTopMenu,
        handleClick,
        auth,
    );

    return (
        <>
            <Logo />
            <Stack
                spacing={1}
                direction='row'
                justifyContent='space-between'
                sx={{
                    flexGrow: 1,
                }}
            >
                {startItems}
                {menuItems.length > 0 && (
                    <>
                        <Tooltip title='More'>
                            <IconButton onClick={handleOpen} sx={{ color: 'white' }}>
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {menuItems}
                        </Menu>
                    </>
                )}
                {endItems}
            </Stack>
        </>
    );
};

const NavbarMenu: React.FC<NavbarMenuProps> = ({ isTopMenu }) => {
    const auth = useAuth();

    if (auth.status === AuthStatus.Loading) {
        return <Logo />;
    }

    if (isTopMenu) {
        return <TopMenu isTopMenu={isTopMenu} auth={auth} />;
    }
    return <BottomMenu isTopMenu={isTopMenu} auth={auth} />;
};

export default NavbarMenu;
