import axios, { AxiosResponse } from 'axios';

import { ListUsersResponse, User } from '../database/user';
import { getConfig } from '../config';
import { MediaUploadRequest, PresignedUrlResponse } from './theSourceApi';

const BASE_URL = getConfig().api.baseUrl;

/**
 * UserApiContextType provides an API for interacting with the current signed-in user.
 */
export type UserApiContextType = {
    /**
     * updateUser applies the given updates to the current signed-in user.
     * @param update The updates to apply.
     * @returns An AxiosResponse containing the updated user in the data field.
     */
    updateUser: (
        update: Partial<User>,
    ) => Promise<AxiosResponse<User, any>>;
    /**
     * checkUserAccess returns a 200 OK if the user is obtained from Cognito and DynamoDB
     * @returns An empty AxiosResponse if the current user exists.
     */
    checkUserAccess: () => Promise<AxiosResponse<any, any>>;

    /**
     * getUser returns the current signed-in user.
     * @returns An AxiosResponse containing the current user in the data field.
     */
    getUser: () => Promise<AxiosResponse<User, any>>;

    /**
     * getUserPublic returns the user with the provided username.
     * @returns An AxiosResponse containing the provided user in the data field.
     */
    getUserPublic: (username: string) => Promise<AxiosResponse<User, any>>;

    listUsersPublic: () => Promise<AxiosResponse<ListUsersResponse, any>>;

    deleteUser: () => Promise<AxiosResponse<void, any>>;

    deleteUserPublic: (username: string) => Promise<AxiosResponse<void, any>>;

    userGeneratePresignedUrl: (
        contentType: string,
    ) => Promise<AxiosResponse<PresignedUrlResponse>>;

    uploadProfilePicToS3: (req: MediaUploadRequest) => Promise<AxiosResponse<string>>;
};

/**
 * updateUser applies the given updates to the current signed-in user.
 * @param idToken The id token of the current signed-in user.
 * @param update The updates to apply.
 * @param callback A callback function to invoke with the update after it has succeeded on the backend.
 * @returns An AxiosResponse containing the updated user in the data field.
 */
export async function updateUser(
    idToken: string,
    update: Partial<User>,
    callback: (update: Partial<User>) => void,
) {
    const result = await axios.put<User>(
        `${BASE_URL}/user`,
        { update },
        {
            headers: {
                Authorization: 'Bearer ' + idToken,
            },
        },
    );
    callback(result.data);
    return result;
}

/**
 * checkUserAccess returns a 200 OK if the user is obtained from Cognito and DynamoDB
 * @param idToken The id token of the current signed-in user.
 * @returns An empty AxiosResponse if the current user exists.
 */
export function checkUserAccess(idToken: string) {
    return axios.get(BASE_URL + '/user/access', {
        headers: {
            Authorization: 'Bearer ' + idToken,
        },
    });
}

/**
 * getUser returns the current signed-in user.
 * @param idToken The id token of the current signed-in user.
 * @returns An AxiosResponse containing the current user in the data field.
 */
export function getUser(idToken: string) {
    return axios.get<User>(BASE_URL + '/user', {
        headers: {
            Authorization: 'Bearer ' + idToken,
        },
    });
}

/**
 * getUserPublic returns the public information for the provided username.
 * @param username The user to fetch public information for.
 * @returns An AxiosResponse containing the requested user.
 */
export function getUserPublic(username: string) {
    return axios.get<User>(BASE_URL + '/public/user/' + username);
}

export function listUsersPublic() {
    return axios.get<ListUsersResponse>(BASE_URL + '/public/user/list');
}

export function deleteUser(idToken: string) {
    return axios.delete<void>(BASE_URL + `/user/delete`, {
        headers: {
            Authorization: 'Bearer ' + idToken,
        },
    });
}

export function deleteUserPublic(idToken: string, username: string) {
    return axios.delete<void>(BASE_URL + `/public/user/delete/` + username, {
        headers: {
            Authorization: 'Bearer ' + idToken,
        },
    });
}

export function userGeneratePresignedUrl(
    idToken: string,
    contentType: string,
) {
    return axios.post(
        `${BASE_URL}/user/presign-url`,
        { contentType },
        {
            headers: {
                Authorization: 'Bearer ' + idToken,
            },
        },
    );
}

export function uploadProfilePicToS3(req: MediaUploadRequest) {
    return axios.put(req.presignedUrl, req.mediaContent, {
        headers: {
            'Content-Type': req.contentType,
        },
    });
}