import { Chip, Tooltip } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface CreatedAtChipProps {
    createdAt?: string;
}

const CreatedAtChip: React.FC<CreatedAtChipProps> = ({ createdAt }) => {
    if (!createdAt) {
        return null;
    }

    const date = new Date(createdAt).toLocaleDateString();

    return (
        <Tooltip title={`Member since ${date}`}>
            <Chip
                icon={<CalendarMonthIcon fontSize='small' />}
                label={date}
                variant='outlined'
                color='secondary'
                sx={{
                    width: '125px',
                }}
            />
        </Tooltip>
    );
};

export default CreatedAtChip;
