import Send from '@mui/icons-material/Send';
import { TextField, Stack, IconButton, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useApi } from '../../api/Api';
import { useRequest } from '../../api/Request';
import { CommunityPost } from '../../database/community';

interface CommentEditorProps {
    post: CommunityPost;
    onSave: (post: CommunityPost) => void;
}

const CommentEditor: React.FC<CommentEditorProps> = ({ post, onSave }) => {
    const [comment, setComment] = useState('');
    const commentRequest = useRequest();
    const api = useApi();

    const onCreateComment = () => {
        const content = comment.trim();
        if (content.length === 0) {
            return;
        }

        commentRequest.onStart();
        api.createComment(post.owner, post.createdAt, content)
            .then((response) => {
                onSave(response.data);
                setComment('');
                commentRequest.onSuccess('Successfully saved comment');
            })
            .catch((err) => {
                console.error('Create comment: ', err);
                commentRequest.onFailure(err);
            });
    };

    return (
        <Stack direction='row' spacing={1} alignItems='center'>
            <TextField
                size='small'
                inputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                label='Add a Comment'
                variant='outlined'
                multiline
                minRows={1}
                maxRows={7}
                fullWidth
                margin='normal'
                value={comment}
                onChange={(e) => setComment(e.target.value)}
            />
            {commentRequest.isLoading() ? (
                <CircularProgress size={30} />
            ) : (
                <IconButton type='submit' onClick={onCreateComment} color='primary'>
                    <Send />
                </IconButton>
            )}
        </Stack>
    );
};
export default CommentEditor;
