import { Grid } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useApi } from '../../api/Api';
import { ListCommunityPostsResponse } from '../../api/communityApi';
import { useRequest } from '../../api/Request';
import { CommunityPost } from '../../database/community';
import LoadingPage from '../../loading/LoadingPage';
import LoadMoreButton from '../LoadMoreButton';
import CommunityPostCard from './CommunityPostCard';

interface CommunityPostTabProps {
    query: string;
    data?: ListCommunityPostsResponse;
    setData: (data: ListCommunityPostsResponse) => void;
    // setLastStartKey: (key: string) => void;
}
const CommunityPostTab: React.FC<CommunityPostTabProps> = ({
    query,
    data,
    setData,
    // setLastStartKey,
}) => {
    const getPostsRequest = useRequest<ListCommunityPostsResponse>();
    const api = useApi();

    const onSave = (i: number, post: CommunityPost) => {
        const newData = data?.posts || [];
        setData({
            posts: [...newData.slice(0, i), post, ...newData.slice(i + 1)],
            lastEvaluatedKey: data?.lastEvaluatedKey || '',
        });
    };

    const onDelete = (i: number) => {
        const newData = data?.posts || [];
        setData({
            posts: [...newData.slice(0, i), ...newData.slice(i + 1)],
            lastEvaluatedKey: data?.lastEvaluatedKey || '',
        });
    };

    const handleResponse = useCallback(
        (resp: ListCommunityPostsResponse) => {
            // setLastStartKey(data?.lastEvaluatedKey || '');

            // const seen: Record<string, boolean> = {};
            const newEntries = resp.posts.sort((lhs, rhs) =>
                rhs.createdAt.localeCompare(lhs.createdAt),
            );

            setData({
                posts: newEntries,
                lastEvaluatedKey: resp.lastEvaluatedKey,
            });
        },
        [setData],
    );

    const handleResponseLoadMore = useCallback(
        (resp: ListCommunityPostsResponse) => {
            const newEntries = (data?.posts || []).concat(
                resp.posts.sort((lhs, rhs) => rhs.createdAt.localeCompare(lhs.createdAt)),
            );

            setData({
                posts: newEntries,
                lastEvaluatedKey: resp.lastEvaluatedKey,
            });
        },
        [data, setData],
    );

    useEffect(() => {
        if (!getPostsRequest.isSent()) {
            getPostsRequest.onStart();
            if (query === 'recent') {
                api.listCommunityPosts()
                    .then((resp) => {
                        handleResponse(resp.data);
                        getPostsRequest.onSuccess(resp.data);
                    })
                    .catch((err) => {
                        console.error('listCommunityPosts: ', err);
                        getPostsRequest.onFailure(err);
                    });
            } else if (query === 'my') {
                api.listCommunityPostsByOwner()
                    .then((resp) => {
                        handleResponse(resp.data);
                        getPostsRequest.onSuccess(resp.data);
                    })
                    .catch((err) => {
                        console.error('listCommunityPosts: ', err);
                        getPostsRequest.onFailure(err);
                    });
            }
        }
    }, [getPostsRequest, api, handleResponse, query]);

    const onLoadMore = () => {
        getPostsRequest.onStart();
        if (query === 'recent') {
            api.listCommunityPosts(data?.lastEvaluatedKey || '')
                .then((resp) => {
                    handleResponseLoadMore(resp.data);
                    getPostsRequest.onSuccess(resp.data);
                })
                .catch((err) => {
                    console.error('listCommunityPosts: ', err);
                    getPostsRequest.onFailure(err);
                });
        } else if (query === 'my') {
            api.listCommunityPostsByOwner(data?.lastEvaluatedKey || '')
                .then((resp) => {
                    handleResponseLoadMore(resp.data);
                    getPostsRequest.onSuccess(resp.data);
                })
                .catch((err) => {
                    console.error('listCommunityPosts: ', err);
                    getPostsRequest.onFailure(err);
                });
        }
    };

    if (!getPostsRequest.isSent() || (getPostsRequest.isLoading() && !data)) {
        return <LoadingPage />;
    }

    return (
        <>
            <Grid container>
                {data?.posts.map((post, i) => (
                    <Grid
                        key={post.owner + post.createdAt}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        padding={2}
                    >
                        <CommunityPostCard
                            post={post}
                            onSave={(e) => onSave(i, e)}
                            onDelete={() => onDelete(i)}
                        />
                    </Grid>
                ))}
            </Grid>
            <LoadMoreButton
                request={getPostsRequest}
                startKey={data?.lastEvaluatedKey || ''}
                onLoadMore={onLoadMore}
            />
        </>
    );
};
export default CommunityPostTab;
