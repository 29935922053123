import Typography from '@mui/material/Typography';
import { CommunityPost } from '../../database/community';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Stack,
} from '@mui/material';
import { useState } from 'react';
import CommentEditor from '../comment/CommentEditor';
import CommunityPostModal from './CommunityPostModal';
import { useApi } from '../../api/Api';
import Avatar from '../../profile/info/Avatar';
import { useAuth } from '../../auth/Auth';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { RequestSnackbar, useRequest } from '../../api/Request';
import { LoadingButton } from '@mui/lab';
import { getConfig } from '../../config';

interface CommunityPostProps {
    post: CommunityPost;
    onSave: (post: CommunityPost) => void;
    onDelete: (post: CommunityPost) => void;
}
const CommunityPostCard: React.FC<CommunityPostProps> = ({ post, onSave, onDelete }) => {
    const api = useApi();
    const theme = useTheme();
    const auth = useAuth();
    const user = auth.user!;
    const deletePostRequest = useRequest();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [hovered, setHovered] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const communityImagesBucket = getConfig().media.communityImagesBucket;

    const openModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const handleDelete = (post: CommunityPost) => {
        deletePostRequest.onStart();
        api.deletePost(post)
            .then((response) => {
                onDelete(response.data);
                deletePostRequest.onSuccess('Successfully deleted post');
            })
            .catch((err) => {
                console.error('Delete community post: ', err);
                deletePostRequest.onFailure(err);
            });
    };

    return (
        <>
            <Box
                sx={{
                    boxShadow: hovered
                        ? `0 0 4px 4px ${theme.palette.primary.main}`
                        : `0 0 4px 4px rgba(0, 0, 0, 0.16)`,
                    borderRadius: '16px',
                    borderColor: `${theme.palette.primary.main}`,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    opacity: hovered ? 0.8 : 1,
                }}
            >
                <Dialog
                    open={showDeleteDialog}
                    onClose={
                        deletePostRequest.isLoading()
                            ? undefined
                            : () => setShowDeleteDialog(false)
                    }
                >
                    <RequestSnackbar request={deletePostRequest} />
                    <DialogTitle
                        sx={{
                            color: `${theme.palette.secondary.main}`,
                        }}
                    >
                        Delete this post?
                        <IconButton
                            aria-label='close'
                            onClick={() => setShowDeleteDialog(false)}
                            sx={{
                                position: 'absolute',
                                right: 10,
                                top: 8,
                                color: `${theme.palette.secondary.main}`,
                            }}
                            disabled={deletePostRequest.isLoading()}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            sx={{
                                color: `${theme.palette.secondary.main}`,
                            }}
                        >
                            Are you sure you want to delete this post? You can't undo this
                            action.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            onClick={() => handleDelete(post)}
                            loading={deletePostRequest.isLoading()}
                        >
                            Delete Post
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
                <Stack direction='row' padding='10px' alignItems='center'>
                    <Avatar
                        username={post.owner}
                        displayName={post.ownerDisplayName}
                        size={50}
                    />
                    <Stack paddingLeft='10px'>
                        <Typography variant='body2' fontWeight='bold' marginBottom='0px'>
                            {post.ownerDisplayName}
                        </Typography>
                        <Typography variant='caption'>
                            {new Date(post.createdAt).toLocaleDateString()}
                            {' @ '}
                            {new Date(post.createdAt).toLocaleTimeString([], {
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: true,
                            })}{' '}
                        </Typography>
                    </Stack>
                    {(user.isAdmin || user.username === post.owner) && (
                        <IconButton
                            onClick={() => setShowDeleteDialog(true)}
                            style={{
                                position: 'relative',
                                top: 0,
                                right: 0,
                                marginLeft: 'auto',
                                color: `${theme.palette.primary.main}`,
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </Stack>
                <Box sx={{ padding: '10px', paddingTop: '0px' }}>
                    <div
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={openModal}
                        style={{
                            marginBottom: '10px',
                            cursor: hovered ? 'pointer' : 'default',
                        }}
                    >
                        <Typography
                            variant='body2'
                            sx={{
                                padding: '5px',
                                paddingTop: '0px',
                                overflowWrap: 'break-word',
                            }}
                        >
                            {post.content}
                        </Typography>
                        {(post.containsMedia) && (
                            <img
                                src={post.previewUrl ? post.previewUrl : `${communityImagesBucket}/${post.owner}/${post.createdAt}`}
                                style={{
                                    width: '100%',
                                    objectFit: 'cover',
                                    maxHeight: '600px',
                                    borderRadius: '16px',
                                }}
                                alt={`${post.ownerDisplayName}'s post`}
                            />
                        )}
                    </div>
                    {post.comments.length > 0 && (
                        <Box
                            key={
                                post.comments[post.comments.length - 1].owner +
                                post.comments[post.comments.length - 1].createdAt
                            }
                            sx={{
                                padding: '5px',
                                background: `${theme.palette.secondary.light}`,
                                borderRadius: '16px',
                            }}
                        >
                            <Stack direction='row' spacing={1}>
                                <Avatar
                                    username={
                                        post.comments[post.comments.length - 1].owner
                                    }
                                    displayName={
                                        post.comments[post.comments.length - 1]
                                            .ownerDisplayName
                                    }
                                    size={40}
                                />
                                <Stack direction='column'>
                                    <Typography variant='body2' fontWeight='bold'>
                                        {
                                            post.comments[post.comments.length - 1]
                                                .ownerDisplayName
                                        }{' '}
                                    </Typography>
                                    <Typography variant='caption'>
                                        {new Date(
                                            post.comments[
                                                post.comments.length - 1
                                            ].createdAt,
                                        ).toLocaleDateString()}
                                        {' @ '}
                                        {new Date(
                                            post.comments[
                                                post.comments.length - 1
                                            ].createdAt,
                                        ).toLocaleTimeString([], {
                                            hour: 'numeric',
                                            minute: '2-digit',
                                            hour12: true,
                                        })}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Typography
                                variant='body2'
                                sx={{ overflowWrap: 'break-word', padding: '4px' }}
                            >
                                {post.comments[post.comments.length - 1].content}
                            </Typography>
                        </Box>
                    )}
                    <CommentEditor post={post} onSave={onSave} />
                </Box>
            </Box>
            <CommunityPostModal
                post={post}
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
                onSave={onSave}
            />
        </>
    );
};

export default CommunityPostCard;
