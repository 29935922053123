export enum PaymentMethodType {
    Card = 'card',
    Link = 'link'
}

export enum StripeCapabilityStatus {
    Active = 'active',
    Inactive = 'inactive',
    Pending = 'pending',
}

export enum StripePayoutInterval {
    Manual = 'manual',
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
}

export enum StripePayoutMethod {
    BankAccount = 'bank_account',
    Card = 'card',
}

export interface StripeAccount {
    capabilities: {
        tax_reporting_us_1099_k: StripeCapabilityStatus;
        transfers: StripeCapabilityStatus;
    };
    charges_enabled: boolean;
    country: string;
    default_currency: string;
    deleted: boolean;
    details_submitted: boolean;
    email: string;
    external_accounts: {
        has_more: boolean;
        total_count: number;
        url: string;
        data: {
            id: string;
            object: StripePayoutMethod;
        }[];
    };
    payouts_enabled: boolean;
    settings: {
        payouts: {
            debit_negative_balances: boolean;
            schedule: {
                delay_days: number;
                interval: StripePayoutInterval;
                monthly_anchor: number;
                weekly_anchor: string;
            };
        };
    };
}

export interface PaymentMethod {
    id: string
    object: string
    billing_details: BillingDetails
    card: Card
    created: number
    customer: string
    livemode: boolean
    type: PaymentMethodType
}

export interface BillingDetails {
    address: Address
    email: string
    name: string
    phone: string
}

export interface Address {
    city: string
    country: string
    line1: string
    line2: string
    postal_code: string
    state: string
}

export interface Card {
    brand: string
    display_brand: string
    checks: Checks
    country: string
    exp_month: number
    exp_year: number
    fingerprint: string
    funding: string
    last4: string
    three_d_secure_usage: ThreeDSecureUsage
}

export interface Checks {
    address_line1_check: string
    address_postal_code_check: string
    cvc_check: string
}

export interface ThreeDSecureUsage {
    supported: boolean
}  