import { Grid } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useApi } from '../api/Api';
import { useRequest } from '../api/Request';
import { ListQuestionsResponse } from '../api/theSourceApi';
import LoadMoreButton from '../community/LoadMoreButton';
import LoadingPage from '../loading/LoadingPage';
import QuestionCard from './QuestionCard';
import { QuestionStatus } from './constants/QuestionStatus';
import { ResponseType } from './constants/ResponseType';
import { Question } from '../database/theSource';

export type TabValue = 'recent' | QuestionStatus.Answered | QuestionStatus.Pending | 'my' | ResponseType;

interface TheSourceTabProps {
    query: string;
    data?: ListQuestionsResponse;
    setData: (data: ListQuestionsResponse) => void;
}
const TheSourceTab: React.FC<TheSourceTabProps> = ({ query, data, setData }) => {
    const getPostsRequest = useRequest<ListQuestionsResponse>();
    const api = useApi();
    const listQuestionsRequest = useRequest<ListQuestionsResponse>();

    const handleResponse = useCallback(
        (resp: ListQuestionsResponse) => {
            setData({
                questions: resp.questions,
                lastEvaluatedKey: resp.lastEvaluatedKey,
            });
        },
        [setData],
    );

    const handleResponseLoadMore = useCallback(
        (resp: ListQuestionsResponse) => {
            const newEntries = (data?.questions || []).concat(
                resp.questions.sort((lhs, rhs) =>
                    rhs.createdAt.localeCompare(lhs.createdAt),
                ),
            );

            setData({
                questions: newEntries,
                lastEvaluatedKey: resp.lastEvaluatedKey,
            });
        },
        [data, setData],
    );

    const getQuestionListFunction = useCallback(() => {
        const fetchQuestionsFunctions = {
            recent: api.listMostRecentQuestions,
            [QuestionStatus.Answered]: () => api.listQuestionsByStatus(QuestionStatus.Answered, false),
            [QuestionStatus.Pending]: () => api.listQuestionsByStatus(QuestionStatus.Pending, true),
            my: api.listQuestionsByOwner,
            [ResponseType.Text]: () =>
                api.listQuestionsByResponseType(ResponseType.Text),
            [ResponseType.Video]: () =>
                api.listQuestionsByResponseType(ResponseType.Video),
        };
        const typedQuery = query as TabValue;
        const fetchQuestions = fetchQuestionsFunctions[typedQuery];

        return fetchQuestions
    }, [api, query])

    const onDelete = (i: number) => {
        const newData = data?.questions || [];
        setData({
            questions: [...newData.slice(0, i), ...newData.slice(i + 1)],
            lastEvaluatedKey: data?.lastEvaluatedKey || '',
        });
    };

    const onSave = (i: number, question: Question) => {
        const newData = data?.questions || [];
        setData({
            questions: [...newData.slice(0, i), question, ...newData.slice(i + 1)],
            lastEvaluatedKey: data?.lastEvaluatedKey || '',
        });
    };

    useEffect(() => {
        if (!listQuestionsRequest.isSent()) {
            listQuestionsRequest.onStart();

            const fetchQuestionList = getQuestionListFunction()
            fetchQuestionList()
                .then((resp) => {  
                    handleResponse(resp.data);
                    listQuestionsRequest.onSuccess(resp.data);
                })
                .catch((err) => {
                    console.error('listRecentQuestions: ', err);
                    listQuestionsRequest.onFailure(err);
                });
        }
    }, [handleResponse, getQuestionListFunction, listQuestionsRequest]);

    const onLoadMore = () => {
        listQuestionsRequest.onStart();
        const fetchQuestionList = getQuestionListFunction()
        fetchQuestionList(data?.lastEvaluatedKey || '')
            .then((resp) => {
                handleResponseLoadMore(resp.data);
                listQuestionsRequest.onSuccess(resp.data);
            })
            .catch((err) => {
                console.error('listRecentQuestions: ', err);
                listQuestionsRequest.onFailure(err);
            });
    };

    if (!listQuestionsRequest.isSent() || (listQuestionsRequest.isLoading())) {
        return <LoadingPage />;
    }

    return (
        <>
            <Grid container>
                {data?.questions.map((question, i) => (
                    <Grid
                        key={question.owner + question.createdAt}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        padding={2}
                    >
                        <QuestionCard 
                            question={question}
                            onDelete={() => onDelete(i)}
                            onSave={(question) => onSave(i, question)}
                         />
                    </Grid>
                ))}
            </Grid>
            <LoadMoreButton
                request={getPostsRequest}
                startKey={data?.lastEvaluatedKey || ''}
                onLoadMore={onLoadMore}
            />
        </>
    );
};
export default TheSourceTab;
