import { Chip, Tooltip } from '@mui/material';
import Icon from '../../components/Icon';
import { QuestionStatus } from '../constants/QuestionStatus';

const statusColorMap: Record<QuestionStatus, 'success' | 'warning'> = {
    [QuestionStatus.Answered]: 'success',
    [QuestionStatus.Pending]: 'warning',
};

interface QuestionStatusChipProps {
    status: QuestionStatus;
}

const QuestionStatusChip: React.FC<QuestionStatusChipProps> = ({ status }) => {
    return (
        <Tooltip title={`Status of question: ${status}`}>
            <Chip
                icon={<Icon name={status} />}
                label={status}
                variant='outlined'
                color={statusColorMap[status]}
            />
        </Tooltip>
    );
};

export default QuestionStatusChip;
