import { Stack, Typography } from '@mui/material';
import AboutMeImage from '../../assets/AboutMe.svg';
import { getConfig } from '../../config';

interface AboutMeProps {
    aboutMe?: string;
}

const AboutMe: React.FC<AboutMeProps> = ({ aboutMe }) => {
    const artistName = getConfig().artist.name

    if (!aboutMe) {
        return null;
    }

    return (
        <Stack>
            <img src={AboutMeImage} alt={`${artistName}'s biography`}  />
            <Typography variant='h5'>{aboutMe}</Typography>
        </Stack>
    );
};

export default AboutMe;
