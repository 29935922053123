import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Alert, Button, Container, Divider, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AuthStatus, useAuth } from './Auth';
import { RequestSnackbar, useRequest } from '../api/Request';
import LoadingPage from '../loading/LoadingPage';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import GoogleIcon from '@mui/icons-material/Google';

const SignInPage = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<Record<string, string>>({});
    const request = useRequest();

    if (auth.status === AuthStatus.Loading) {
        return <LoadingPage />;
    }

    if (auth.status === AuthStatus.Authenticated) {
        return <Navigate to='/' />;
    }

    const onSignin = () => {
        const errors: Record<string, string> = {};
        if (email.trim().length === 0) {
            errors.email = 'Email is required';
        }
        if (password.length === 0) {
            errors.password = 'Password is required';
        }

        setErrors(errors);
        if (Object.values(errors).length > 0) {
            return;
        }

        request.onStart();
        auth.signin(email.trim(), password).catch((err) => {
            console.error(err);
            if (
                err.code === 'NotAuthorizedException' ||
                err.code === 'UserNotFoundException'
            ) {
                setErrors({ password: 'Incorrect email or password' });
                request.onFailure({ message: 'Incorrect email or password' });
            } else {
                request.onFailure(err);
            }
        });
    };

    const onGoogleSignIn = () => {
        auth.socialSignin('Google');
    };

    const onFacebookSignIn = () => {
        auth.socialSignin('Facebook');
    };

    return (
        <Container maxWidth='md' sx={{ pt: 0, pb: 4 }}>
            <Stack justifyContent='center' alignItems='center' spacing={6}>
                <RequestSnackbar request={request} />
                <Alert severity='warning'>
                    You must have an account to access certain features in this website.
                </Alert>
                <Stack spacing={2}>
                    <Button
                        variant='contained'
                        onClick={onGoogleSignIn}
                        startIcon={<GoogleIcon />}
                    >
                        Sign in with Google
                    </Button>
                    <Button
                        variant='contained'
                        onClick={onFacebookSignIn}
                        startIcon={<FacebookOutlinedIcon />}
                    >
                        Sign in with Facebook
                    </Button>
                </Stack>
                <Divider sx={{ width: 1 }}>Or</Divider>
                <Stack width={0.75} spacing={4} alignItems='center'>
                    <TextField
                        fullWidth
                        id='email'
                        label='Email'
                        variant='outlined'
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                    <TextField
                        fullWidth
                        id='password'
                        label='Password'
                        type='password'
                        variant='outlined'
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        error={!!errors.password}
                        helperText={errors.password}
                    />
                    <LoadingButton
                        variant='contained'
                        fullWidth
                        sx={{ textTransform: 'none' }}
                        onClick={onSignin}
                        loading={request.isLoading()}
                    >
                        Sign in
                    </LoadingButton>
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        sx={{ width: 1 }}
                    >
                        <Button
                            variant='text'
                            sx={{ textTransform: 'none' }}
                            onClick={() => navigate('/signup')}
                        >
                            No account? Sign Up
                        </Button>
                        <Button
                            variant='text'
                            sx={{ textTransform: 'none', alignSelf: 'end' }}
                            onClick={() => navigate('/forgot-password')}
                        >
                            Forgot password?
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Container>
    );
};

export default SignInPage;
