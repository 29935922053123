import { Stack, useTheme } from '@mui/material';

const LoadingPage = () => {
    const theme = useTheme();

    return (
        <Stack sx={{ pt: 6, pb: 4 }} justifyContent='center' alignItems='center'>
            <video
                autoPlay
                playsInline
                loop
                muted
                style={{
                    width: '50%',
                    height: '50%',
                    padding: 0,
                    borderWidth: '3px',
                    borderStyle: 'solid',
                    borderRadius: '10px',
                    borderColor: `${theme.palette.primary.main}`,
                }}
            >
                <source src='LoadingScreen.mp4' type='video/mp4' />
                Your browser does not support the video tag.
            </video>
        </Stack>
    );
};

export default LoadingPage;
