import { Stack, Typography } from '@mui/material';
import FunFactImage from '../../assets/FunFact.svg';
import { getConfig } from '../../config';

interface FunFactProps {
    funFact?: string;
}

const FunFact: React.FC<FunFactProps> = ({ funFact }) => {
    const artistName = getConfig().artist.name

    if (!funFact) {
        return null;
    }

    return (
        <Stack>
            <img src={FunFactImage} alt={`${artistName}'s fun fact`} />
            <Typography variant='h5'>{funFact}</Typography>
        </Stack>
    );
};

export default FunFact;
