import { createContext, ReactNode, useContext, useState } from 'react';

/**
 * CacheContextType defines the type of the cache as available through CacheProvider
 */
type CacheContextType = {
    isLoading: boolean;
    setIsLoading: (arg: boolean) => void;
    imageBypass: number;
    setImageBypass: (v: number) => void;
};

const CacheContext = createContext<CacheContextType>(null!);

/**
 * @returns The current CacheContextType value.
 */
export function useCache() {
    return useContext(CacheContext);
}

/**
 * CacheProvider provides access to cached data. It implements the CacheContextType interface.
 * @param param0 React props. The only used prop is children.
 * @returns A CacheContext.Provider wrapping the provided children.
 */
export function CacheProvider({ children }: { children: ReactNode }) {
    const [isLoading, setIsLoading] = useState(false);
    const [imageBypass, setImageBypass] = useState(Date.now());

    const value = {
        isLoading,
        setIsLoading,
        imageBypass,
        setImageBypass,
    };
    return <CacheContext.Provider value={value}>{children}</CacheContext.Provider>;
}
