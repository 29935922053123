import { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { useRequest } from '../api/Request';
import { useApi } from '../api/Api';
import { Question } from '../database/theSource';
import { AuthStatus, useAuth } from '../auth/Auth';
import QuestionSubmissionModal from './QuestionSubmissionModal';
import { User } from '../database/user';
import { ResponseType } from './constants/ResponseType';
import { getConfig } from '../config';
import { useSearchParams } from 'react-router-dom';

interface QuestionSubmissionFormProps {
    onSubmit: (question: Question) => void;
}

const QuestionSubmissionForm: React.FC<QuestionSubmissionFormProps> = ({ onSubmit }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [responseType, setResponseType] = useState<string>(searchParams.get('responseType') || ResponseType.Text);
    const [isAcknowledged, setIsAcknowledged] = useState<boolean>(searchParams.get('acknowledgement') === 'true' ? true : false);
    const [videoResponseCost, setVideoResponseCost] = useState<number>();
    const [textResponseCost, setTextResponseCost] = useState<number>();
    const [questionContent, setQuestionContent] = useState(searchParams.get('questionContent') || '');
    const [helperText, setHelperText] = useState<string>('150 character limit');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(searchParams.toString().trim().length > 0);
    const getArtistUserRequest = useRequest<User>();
    const api = useApi();
    const theme = useTheme();
    const auth = useAuth();
    const user = auth.user!;
    const artistName = getConfig().artist.name

    useEffect(() => {
        if (!getArtistUserRequest.isSent()) {
            getArtistUserRequest.onStart();
            api.getUserPublic('artistUsername')
                .then((resp) => {
                    setVideoResponseCost(resp.data.artistInfo?.costVideoResponseType);
                    setTextResponseCost(resp.data.artistInfo?.costTextResponseType);
                    getArtistUserRequest.onSuccess(resp.data);
                })
                .catch((err) => {
                    console.error('Error fetching artist data:', err);
                    getArtistUserRequest.onFailure(err);
                });
        }
    }, [getArtistUserRequest, api]);

    const handleOpenModal = () => {
        setIsModalOpen(true);
        setSearchParams();
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSearchParams();
    };

    const handleResponseTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const type = event.target.value as ResponseType;
        setResponseType(type);
    };

    const getCost = (textCost: number, videoCost: number): number => {
        switch (responseType as ResponseType) {
            case ResponseType.Text: {
                return textCost
            }
            case ResponseType.Video: {
                return videoCost
            }
        }
    }

    if (
        getArtistUserRequest.isLoading() ||
        auth.status === AuthStatus.Loading
    ) {
        return (
            <Stack justifyContent='center' alignItems='center' padding={10}>
                <CircularProgress />
            </Stack>
        );
    } else if (!getArtistUserRequest.data || !getArtistUserRequest.data.artistInfo || !getArtistUserRequest.data.artistInfo.stripeAccountId || !getArtistUserRequest.data.artistInfo.onboardingComplete || videoResponseCost === undefined || videoResponseCost === 0 || textResponseCost === undefined || textResponseCost === 0) {
        return (
            <Container
                maxWidth='xl'
                sx={{
                    mt: '50px',
                }}
            >
                <Stack alignItems='center'>
                    <Typography variant='h6'>
                        {artistName} has not set up The Source yet, check back in later.
                    </Typography>
                </Stack>
            </Container>
        );
    } else {
        return (
            <Container
                maxWidth='xl'
                sx={{
                    mt: '50px',
                }}
            >
                <Stack
                    direction='row'
                    spacing={4}
                    alignItems='flex-start'
                    flexWrap='wrap'
                    rowGap={3}
                >
                    <TextField
                        label={`Ask ${artistName} a Question`}
                        placeholder={`Ask ${artistName} a Question`}
                        value={questionContent}
                        onChange={(e) => {
                            setHelperText(
                                `${150 - e.target.value.length} characters remaining`,
                            );
                            setQuestionContent(e.target.value);
                        }}
                        multiline
                        minRows={1}
                        maxRows={3}
                        inputProps={{ maxLength: 150 }}
                        helperText={helperText}
                        sx={{ flexGrow: 1 }}
                    />
                    <Stack direction='row' spacing={4} justifyContent='center'>
                        <FormControl>
                            <FormLabel sx={{ color: `${theme.palette.primary.main}` }}>
                                Artist Response Type
                            </FormLabel>
                            <RadioGroup
                                value={responseType}
                                onChange={handleResponseTypeChange}
                                row
                            >
                                <FormControlLabel
                                    value={ResponseType.Text}
                                    control={<Radio />}
                                    label={ResponseType.Text}
                                />
                                <FormControlLabel
                                    value={ResponseType.Video}
                                    control={<Radio />}
                                    label={ResponseType.Video}
                                />
                            </RadioGroup>
                        </FormControl>
                        <Button
                            variant='contained'
                            onClick={handleOpenModal}
                            disabled={questionContent.trim().length === 0}
                            sx={{
                                opacity:
                                    questionContent.trim().length === 0 ? '50%' : '100%',
                            }}
                        >
                            Buy Question
                        </Button>
                    </Stack>
                </Stack>
                <QuestionSubmissionModal
                    isModalOpen={isModalOpen}
                    handleCloseModal={handleCloseModal}
                    onSubmit={(question) => {
                        setQuestionContent('')
                        setResponseType(ResponseType.Text)
                        onSubmit(question)
                    }}
                    user={user}
                    questionContent={questionContent}
                    responseType={responseType}
                    cost={getCost(textResponseCost, videoResponseCost)}
                    isAcknowledged={isAcknowledged}
                    setIsAcknowledged={setIsAcknowledged}
                />
            </Container>
        );
    }
};

export default QuestionSubmissionForm;
