import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {
    BrowseGallery,
    CheckBox,
    EditNote,
    Help,
    Movie,
    Paid,
    PlayCircleFilled,
    RestartAlt,
    Timer,
    WatchLater,
} from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { forwardRef } from 'react';
import { QuestionStatus } from '../theSource/constants/QuestionStatus';
import { ResponseType } from '../theSource/constants/ResponseType';

export const icons = {
    responseTypes: Movie,
    [ResponseType.Text]: EditNote,
    [ResponseType.Video]: PlayCircleFilled,
    statuses: BrowseGallery,
    [QuestionStatus.Answered]: CheckBox,
    [QuestionStatus.Pending]: WatchLater,
    reset: RestartAlt,
    cost: Paid,
    question: Help,
    timer: Timer,
};

export type IconName = keyof typeof icons;

export interface IconProps extends SvgIconProps {
    name?: IconName;
}

export const Icon: React.FC<IconProps> = ({ name, ...props }) => {
    if (!name || !icons[name]) {
        return null;
    }

    const InternalIcon = icons[name];
    return <InternalIcon {...props} />;
};

export default Icon;

type FontAwesomeSvgIconProps = SvgIconProps & {
    icon: IconDefinition;
};

export const FontAwesomeSvgIcon = forwardRef<SVGSVGElement, FontAwesomeSvgIconProps>(
    (props, ref) => {
        const { icon, ...others } = props;

        const {
            icon: [width, height, , , svgPathData],
        } = icon;

        return (
            <SvgIcon
                ref={ref}
                viewBox={`0 0 ${width} ${height}`}
                fontSize='small'
                className='MuiChip-icon'
                {...others}
            >
                {typeof svgPathData === 'string' ? (
                    <path d={svgPathData} />
                ) : (
                    /**
                     * A multi-path Font Awesome icon seems to imply a duotune icon. The 0th path seems to
                     * be the faded element (referred to as the "secondary" path in the Font Awesome docs)
                     * of a duotone icon. 40% is the default opacity.
                     *
                     * @see https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons#changing-opacity
                     */
                    svgPathData.map((d: string, i: number) => (
                        <path key={i} style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />
                    ))
                )}
            </SvgIcon>
        );
    },
);
FontAwesomeSvgIcon.displayName = 'FontAwesomeSvgIcon';
