import { Stack } from '@mui/material';
import MusicPick from '../../assets/MusicPick.svg';
import { getConfig } from '../../config';

interface TrackProps {
    trackId?: string;
}

const TrackChoice: React.FC<TrackProps> = ({ trackId }) => {
    const artistName = getConfig().artist.name

    if (!trackId) {
        return null;
    }

    return (
        <Stack>
            <img src={MusicPick} alt={`${artistName}'s Spotify pick`} />
            <iframe
                key={trackId}
                title={`${artistName}'s Spotify pick`}
                style={{
                    border: 'none',
                }}
                src={`https://open.spotify.com/embed/track/${trackId}?utm_source=generator&theme=0`}
                height='200'
                allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
                loading='eager'
            ></iframe>
        </Stack>
    );
};

export default TrackChoice;
