import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Amplify } from 'aws-amplify';

import { getConfig } from './config';
import { AuthProvider, RequireAuth } from './auth/Auth';
import { ApiProvider } from './api/Api';
import ErrorBoundary from './ErrorBoundary';
import SignUpPage from './auth/SignUpPage';
import SignInPage from './auth/SignInPage';
import HomePage from './home/HomePage';
import VerifyEmailPage from './auth/VerifyEmailPage';
import ForgotPasswordPage from './auth/ForgotPasswordPage';
import Navbar from './navbar/Navbar';
import EventsPage from './events/EventsPage';
import MusicPage from './music/MusicPage';
import CommunityPage from './community/CommunityPage';
import NotFoundPage from './NotFoundPage';
import ThemeProvider from './ThemeProvider';
import VideosPage from './videos/VideosPage';
import AdminPage from './admin/AdminPage';
import ProfilePage from './profile/ProfilePage';
import ProfileEditorPage from './profile/editor/ProfileEditorPage';
import { CacheProvider } from './api/cache/Cache';
import TheSourcePage from './theSource/TheSourcePage';

const config = getConfig();
Amplify.configure({
    Auth: {
        region: config.auth.region,
        userPoolId: config.auth.userPoolId,
        userPoolWebClientId: config.auth.userPoolWebClientId,
        oauth: {
            domain: config.auth.oauth.domain,
            scope: config.auth.oauth.scope,
            redirectSignIn: config.auth.oauth.redirectSignIn,
            redirectSignOut: config.auth.oauth.redirectSignOut,
            responseType: config.auth.oauth.responseType,
        },
    },
});

function App() {
    return (
        <AuthProvider>
            <ThemeProvider>
                <ApiProvider>
                    <CacheProvider>
                        <BrowserRouter>
                            <Navbar />
                            <ErrorBoundary>
                                <Router />
                            </ErrorBoundary>
                        </BrowserRouter>
                    </CacheProvider>
                </ApiProvider>
            </ThemeProvider>
        </AuthProvider>
    );
}

function Router() {
    return (
        <Routes>
            <Route path='/'>
                <Route index element={<HomePage />} />
                <Route path='signin' element={<SignInPage />} />
                <Route path='signup' element={<SignUpPage />} />
                <Route path='verify-email' element={<VerifyEmailPage />} />
                <Route path='forgot-password' element={<ForgotPasswordPage />} />
                <Route path='events' element={<EventsPage />} />
                <Route path='music' element={<MusicPage />} />
                <Route path='videos' element={<VideosPage />} />
                <Route element={<RequireAuth />}>
                    <Route path='profile'>
                        <Route index element={<ProfilePage />} />
                        <Route path='edit' element={<ProfileEditorPage />} />
                        <Route path=':username' element={<ProfilePage />} />
                    </Route>
                    <Route path='community' element={<CommunityPage />} />
                    <Route path='admin' element={<AdminPage />} />
                    <Route path='the-source' element={<TheSourcePage />} />
                </Route>
                <Route path='*' element={<NotFoundPage />} />
            </Route>
        </Routes>
    );
}
export default App;
