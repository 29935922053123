import { Button, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import VideoPick from '../../assets/VideoPick.svg';
import { getConfig } from '../../config';
import { SingleYouTubeVideo } from '../../database/videos';

interface VideoProps {
    video?: SingleYouTubeVideo;
}

const VideoChoice: React.FC<VideoProps> = ({ video }) => {
    const artistName = getConfig().artist.name

    const handleAlbumClick = (url: string) => {
        window.open(url, '_blank');
    };

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const theme = useTheme();
    const [hovered, setHovered] = useState(false);

    if (!video) {
        return null;
    }

    return (
        <Stack>
            <img src={VideoPick} alt={`${artistName}'s video pick`} />
            <Button
                variant='outlined'
                sx={{
                    ':hover': {
                        boxShadow: `0 0 4px 4px ${theme.palette.primary.main}`,
                    },
                    padding: 0,
                    borderRadius: '10px',
                    borderColor: `${theme.palette.primary.main}`,
                    zIndex: '1',
                    position: 'relative',
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                    handleAlbumClick(`https://www.youtube.com/watch?v=${video.id}`);
                }}
            >
                <img
                    style={{
                        borderRadius: '10px',
                        width: '100%',
                        opacity: hovered ? 0.1 : 1,
                    }}
                    src={video.snippet.thumbnails.maxres.url}
                    alt={video.snippet.title}
                />
                <Stack
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: hovered
                            ? ''
                            : `${theme.palette.background.default}`,
                        opacity: 0.8,
                        borderRadius: '20px',
                        padding: '4px',
                    }}
                >
                    <Typography
                        variant='body1'
                        textTransform='none'
                        sx={{
                            color: theme.palette.secondary.main,
                        }}
                    >
                        {video.snippet.title}
                    </Typography>
                    <Typography
                        variant='body1'
                        sx={{
                            color: theme.palette.secondary.main,
                        }}
                    >
                        {video.snippet.publishedAt &&
                            new Date(video.snippet.publishedAt).toLocaleDateString()}
                    </Typography>
                </Stack>
            </Button>
        </Stack>
    );
};

export default VideoChoice;
