import { Stack, Typography } from '@mui/material';

interface TitleAndDescriptionProps {
    title?: string;
    description?: string | number;
}

const TitleAndDescription: React.FC<TitleAndDescriptionProps> = ({
    title,
    description,
}) => {
    if (!description) {
        return null;
    }

    return (
        <Stack>
            <Typography variant='h5' fontWeight='bold' color='primary'>
                {title}
            </Typography>
            <Typography variant='h6' sx={{ whiteSpace: 'pre-line' }}>
                {description}
            </Typography>
        </Stack>
    );
};

export default TitleAndDescription;
