import {
    Container,
    Grid,
    Skeleton,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useApi } from '../api/Api';
import { useRequest } from '../api/Request';
import { AuthStatus, useAuth } from '../auth/Auth';
import { Track } from '../database/music';
import { SingleYouTubeVideoList } from '../database/videos';
import SocialBar from '../socials/SocialBar';
import MogleyDavi from '../assets/MogleyDavi.svg';
import FunFact from './components/FunFact';
import AboutMe from './components/AboutMe';
import { User } from '../database/user';
import LoadingPage from '../loading/LoadingPage';
import MusicChoice from './components/MusicChoice';
import VideoChoice from './components/VideoChoice';

const HomePage = () => {
    const auth = useAuth();
    const api = useApi();
    const userRequest = useRequest<User>();
    const trackRequest = useRequest<Track>();
    const videoRequest = useRequest<SingleYouTubeVideoList>();
    const [artistUser, setArtistUser] = useState<User>();
    const isMogleyDaviShowing = useMediaQuery('(min-width:900px)');

    useEffect(() => {
        <script src='https://open.spotify.com/embed/iframe-api/v1' async></script>;
        if (!userRequest.isSent()) {
            userRequest.onStart();
            api.getUserPublic('artistUsername')
                .then((resp) => {
                    setArtistUser(resp.data);
                    api.getTrackById(resp.data.musicId)
                        .then((resp) => {
                            trackRequest.onSuccess(resp.data);
                        })
                        .catch((err) => {
                            console.error('Track error: ', err);
                            trackRequest.onFailure(err);
                        });
                    api.getYouTubeVideoById(resp.data.videoId)
                        .then((response) => {
                            videoRequest.onSuccess(response.data);
                        })
                        .catch((err) => {
                            console.error('Video error: ', err);
                            videoRequest.onFailure(err);
                        });
                })
                .catch((err) => {
                    trackRequest.onFailure(err);
                });
        }
    }, [userRequest, videoRequest, trackRequest, api]);

    if (auth.status === AuthStatus.Loading && !trackRequest.data && !videoRequest.data) {
        return <LoadingPage />;
    }

    return (
        <Container
            maxWidth={false}
            sx={{ pt: 6, pb: 4, margin: 0, padding: 0, marginBottom: '100px' }}
        >
            <div
                className='video-container'
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: -10,
                }}
            >
                <video
                    autoPlay
                    playsInline
                    loop
                    muted
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        filter: 'brightness(50%)',
                    }}
                >
                    <source src='HomeVideo.mp4' type='video/mp4' />
                    Your browser does not support the video tag.
                </video>
            </div>

            {isMogleyDaviShowing && (
                <img
                    alt='Mogley Davi'
                    style={{
                        position: 'fixed',
                        top: -20,
                        right: 300,
                        width: '325px',
                        height: '325px',
                        zIndex: -1,
                    }}
                    src={MogleyDavi}
                />
            )}
            <Grid container sx={{ maxWidth: '100%', margin: 0 }}>
                <Grid
                    item
                    md={3.3}
                    sm={12}
                    xs={12}
                    padding='30px'
                    sx={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Stack maxWidth='400px'>
                        {trackRequest.data ? (
                            <MusicChoice trackId={trackRequest.data?.id} />
                        ) : (
                            <Skeleton variant='rounded' animation='wave' width='100%' />
                        )}

                        {videoRequest.data ? (
                            <VideoChoice video={videoRequest.data?.items[0]} />
                        ) : (
                            <Skeleton variant='rounded' animation='wave' width='100%' />
                        )}
                    </Stack>
                </Grid>
                <Grid item md={5.1} sm={0} xs={0}></Grid>
                <Grid
                    item
                    md={3.6}
                    sm={12}
                    xs={12}
                    marginTop='30px'
                    padding='30px'
                    sx={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Stack spacing={2} maxWidth='400px'>
                        <Typography variant='h5'>
                            {artistUser?.bio ? (
                                <AboutMe aboutMe={artistUser?.bio} />
                            ) : (
                                <Skeleton variant='rounded' animation='wave' />
                            )}
                        </Typography>
                        <Typography variant='h5'>
                            {artistUser?.funFact ? (
                                <FunFact funFact={artistUser?.funFact} />
                            ) : (
                                <Skeleton variant='rounded' animation='wave' />
                            )}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <SocialBar />
        </Container>
    );
};

export default HomePage;
