import { Button, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Album } from '../database/music';
import { YouTubeVideo } from '../database/videos';

export interface MediaItemProps {
    album?: Album;
    video?: YouTubeVideo;
}

export interface Media {
    external_url: string;
    image_url: string;
    name: string;
    release_date: string;
}

const MediaItem: React.FC<MediaItemProps> = ({ album, video }) => {
    useEffect(() => {
        if (album) {
            const albumObj: Media = {
                external_url: album.external_urls.spotify,
                image_url: album.images[1].url,
                name: album.name,
                release_date: album.release_date,
            };
            setMediaObj(albumObj);
        } else if (video) {
            const videoObj: Media = {
                external_url: `https://www.youtube.com/watch?v=${video.id.videoId}`,
                image_url: video.snippet.thumbnails.medium.url,
                name: video.snippet.title,
                release_date: video.snippet.publishedAt.toString(),
            };
            setMediaObj(videoObj);
        }
    }, [album, video]);

    const theme = useTheme();
    const [hovered, setHovered] = useState(false);
    const [mediaObj, setMediaObj] = useState<Media>();

    const handleClick = (url: string) => {
        window.open(url, '_blank');
    };

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    if (!mediaObj) {
        return null;
    }

    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '50px',
            }}
        >
            <Button
                variant='outlined'
                sx={{
                    ':hover': {
                        boxShadow: `0 0 4px 4px ${theme.palette.primary.main}`,
                    },
                    padding: 0,
                    borderRadius: '10px',
                    borderColor: `${theme.palette.primary.main}`,
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                    handleClick(mediaObj.external_url);
                }}
            >
                <img
                    style={{
                        borderRadius: '10px',
                        height: '100%',
                        opacity: hovered ? 0.1 : 1,
                    }}
                    src={mediaObj.image_url}
                    alt={mediaObj.name}
                />
                {(hovered || video) && (
                    <Stack
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor:
                                video && !hovered
                                    ? `${theme.palette.background.default}`
                                    : '',
                            opacity: video ? 0.8 : '',
                            borderRadius: video ? '20px' : '',
                            padding: video ? '4px' : '',
                        }}
                    >
                        <Typography
                            variant='body1'
                            textTransform='none'
                            sx={{
                                color: theme.palette.secondary.main,
                            }}
                        >
                            {mediaObj.name}
                        </Typography>
                        <Typography
                            variant='body1'
                            sx={{
                                color: theme.palette.secondary.main,
                            }}
                        >
                            {new Date(mediaObj.release_date).toLocaleDateString()}
                        </Typography>
                    </Stack>
                )}
            </Button>
        </Grid>
    );
};

export default MediaItem;
