import { Box, Container, Grid, Stack } from '@mui/material';
import { AuthStatus, useAuth } from '../auth/Auth';
import { useEffect } from 'react';
import { useApi } from '../api/Api';
import { RequestSnackbar, useRequest } from '../api/Request';
import { YouTubeVideoList } from '../database/videos';
import SocialBar from '../socials/SocialBar';
import LoadingPage from '../loading/LoadingPage';
import MediaItem from '../music/MediaItem';

const VideosPage = () => {
    const auth = useAuth();
    // const theme = useTheme();
    // const navigate = useNavigate();
    const api = useApi();
    const videoRequest = useRequest<YouTubeVideoList>();

    // const handleAlbumClick = (url: string) => {
    //     window.open(url, '_blank');
    // };

    useEffect(() => {
        if (!videoRequest.isSent()) {
            videoRequest.onStart();
            api.getYouTubeVideos()
                .then((response) => {
                    videoRequest.onSuccess(response.data);
                })
                .catch((err) => {
                    console.error(err);
                    videoRequest.onFailure(err);
                });
        }
    }, [api, videoRequest]);

    if (auth.status === AuthStatus.Loading) {
        return <LoadingPage />;
    }

    if (!videoRequest.isSent() || videoRequest.isLoading()) {
        return <LoadingPage />;
    }

    if (!videoRequest.data) {
        return (
            <Stack>
                <RequestSnackbar request={videoRequest} />
            </Stack>
        );
    }

    return (
        <Container maxWidth='lg' sx={{ pt: 6, pb: 4, mb: '100px' }}>
            <Stack spacing={4}>
                <Box sx={{ width: '100%', typography: 'body1', mt: 5 }}>
                    <Grid container justifyContent='center'>
                        {videoRequest.data.items.map((video) => (
                            <MediaItem video={video} key={video.id.videoId} />
                        ))}
                    </Grid>
                </Box>
            </Stack>
            <SocialBar />
        </Container>
    );
};

export default VideosPage;
