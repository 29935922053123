import { LoadingButton } from '@mui/lab';
import {
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Stack,
    useTheme,
} from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowsProp,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { useApi } from '../api/Api';
import { RequestSnackbar, useRequest } from '../api/Request';
import { AuthStatus, useAuth, useRequiredAuth } from '../auth/Auth';
import LoadingPage from '../loading/LoadingPage';
import Avatar from '../profile/info/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import ConnectStripeAccount from './payment/ConnectStripeAccount';
import StripeInfo from './payment/StripeInfo';
import { ListUsersResponse } from '../database/user';

const HomePage = () => {
    const auth = useAuth();
    const { user } = useRequiredAuth();
    const theme = useTheme();
    const api = useApi();
    const request = useRequest<ListUsersResponse>();
    const [rows, setRows] = useState<GridRowsProp>([]);
    // const [lastStartKey, setLastStartKey] = useState('');
    const [data, setData] = useState<ListUsersResponse>();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [userToDelete, setUserToDelete] = useState<string>('');
    const [idToDelete, setIdToDelete] = useState<number>(0);

    const deleteUser = (username: string, index: number) => {
        api.deleteUserPublic(username);
        const newData = data?.users || [];
        setData({
            users: [...newData.slice(0, index), ...newData.slice(index + 1)],
            lastEvaluatedKey: data?.lastEvaluatedKey || '',
        });
        setShowDeleteDialog(false);
        setRows((prevRows) => prevRows.filter((row) => row.id !== index + 1));
    };

    const handleResponse = useCallback(
        (resp: ListUsersResponse) => {
            // setLastStartKey(data?.lastEvaluatedKey || '');

            // const seen: Record<string, boolean> = {};
            const newEntries = (data?.users || []).concat(
                resp.users.sort((lhs, rhs) => rhs.createdAt.localeCompare(lhs.createdAt)),
            );
            // .filter((e) => {
            //     return seen.hasOwnProperty(e.id) ? false : (seen[e.id] = true);
            // });

            setData({
                users: newEntries,
            });
        },
        [data],
    );

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: '#',
            maxWidth: 100,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'avatar',
            headerName: 'Avatar',
            minWidth: 125,
            renderCell: (params: GridRenderCellParams<any, Array<string>>) => {
                if (!params.value) {
                    return;
                }
                return (
                    <Avatar
                        username={params.value[0]}
                        displayName={params.value[1]}
                        size={50}
                    />
                );
            },
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'displayName',
            headerName: 'Display Name',
            minWidth: 225,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'bio',
            headerName: 'Bio',
            minWidth: 270,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            minWidth: 225,
            valueFormatter: (value: string) => {
                const formattedDate = new Date(value).toLocaleDateString();
                const formattedTime = new Date(value).toLocaleTimeString([], {
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true,
                });
                const formattedDateTime = `${formattedDate} @ ${formattedTime}`;
                return formattedDateTime;
            },
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'deleteUser',
            headerName: 'Delete User',
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, Array<string>>) => {
                if (!params.value) {
                    return;
                }
                const username = params.value[0];
                const index = params.value[1];
                return (
                    <Button
                        variant='contained'
                        onClick={() => {
                            setUserToDelete(username);
                            setIdToDelete(Number(index));
                            setShowDeleteDialog(true);
                        }}
                    >
                        Delete User
                    </Button>
                );
            },
            headerAlign: 'center',
            align: 'center',
        },
    ];

    useEffect(() => {
        if (!request.isSent()) {
            request.onStart();
            api.listUsersPublic()
                .then((response) => {
                    handleResponse(response.data);
                    const newRows = response.data.users.map((user, i) => ({
                        id: i + 1,
                        avatar: [user.username, user.displayName],
                        displayName: user.displayName,
                        bio: user.bio,
                        createdAt: user.createdAt,
                        deleteUser: [user.username, i],
                    }));
                    setRows(newRows);
                    request.onSuccess(response.data);
                })
                .catch((err) => {
                    console.error(err);
                    request.onFailure(err);
                });
        }
    }, [request, api, handleResponse]);

    if (auth.status === AuthStatus.Loading) {
        return <LoadingPage />;
    }

    return (
        <Container sx={{ pt: 6, pb: 4 }}>
            {user.isArtist && (
                user.artistInfo?.stripeAccountId ? (
                    <>
                        <StripeInfo />
                    </>
                ) : (
                    <ConnectStripeAccount />
                )
            )}

            <Dialog
                open={showDeleteDialog}
                onClose={
                    request.isLoading() ? undefined : () => setShowDeleteDialog(false)
                }
            >
                <RequestSnackbar request={request} />
                <DialogTitle
                    sx={{
                        color: `${theme.palette.secondary.main}`,
                    }}
                >
                    Delete this user?
                    <IconButton
                        aria-label='close'
                        onClick={() => setShowDeleteDialog(false)}
                        sx={{
                            position: 'absolute',
                            right: 10,
                            top: 8,
                            color: `${theme.palette.secondary.main}`,
                        }}
                        disabled={request.isLoading()}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        sx={{
                            color: `${theme.palette.secondary.main}`,
                        }}
                    >
                        Are you sure you want to delete this user? You can't undo this
                        action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        onClick={() => deleteUser(userToDelete, idToDelete)}
                        loading={request.isLoading()}
                    >
                        Delete User
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            {request.isLoading() ? (
                <Stack justifyContent='center' alignItems='center' padding={20}>
                    <CircularProgress />
                </Stack>
            ) : (
                <DataGrid
                    rows={rows}
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    sx={{
                        height: 'calc(100vh - 150px)',
                        '&.MuiDataGrid-root': {
                            bgcolor: `${theme.palette.secondary.light}`,
                        },
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: `${theme.palette.primary.main}`,
                        },
                        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                            py: '8px',
                        },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                            py: '15px',
                        },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                            py: '22px',
                        },
                    }}
                />
            )}
        </Container>
    );
};

export default HomePage;
