import { ProfilePicAction } from "../profile/editor/ProfileEditorPage";

interface CognitoSession {
    idToken: {
        jwtToken: string;
    };
    refreshToken: {
        token: string;
    };
    accessToken: {
        jwtToken: string;
    };
}

export interface CognitoUser {
    session: CognitoSession;
    username: string;
    rawResponse: any;
}

export interface ArtistInfo {
    stripeAccountId: string;
    onboardingComplete: boolean;
    costTextResponseType: number;
    costVideoResponseType: number;
}

export interface PaymentInfo {
    customerId: string;
    paymentMethodId: string;
}

export function parseCognitoResponse(cognitoResponse: any) {
    return {
        session: cognitoResponse.signInUserSession,
        username: cognitoResponse.username,
        rawResponse: cognitoResponse,
    };
}

export interface ListUsersResponse {
    users: User[];
    lastEvaluatedKey?: string;
}

export interface User {
    cognitoUser?: CognitoUser;
    username: string;
    email: string;
    displayName: string;
    bio: string;
    isAdmin: boolean;
    isArtist: boolean;
    createdAt: string;
    updatedAt: string;
    funFact: string;
    musicId: string;
    videoId: string;
    artistInfo?: ArtistInfo;
    hasCreatedProfile: boolean;
    hasProfilePicture: boolean;
    profilePicAction?: ProfilePicAction;
    paymentInfo?: PaymentInfo;
}

export function parseUser(apiResponse: any, cognitoUser?: CognitoUser) {
    return {
        ...apiResponse,
        cognitoUser,
    };
}

export function hasCreatedProfile(user: User): boolean {
    if (user.displayName.trim() === '' || !user.hasCreatedProfile) {
        return false;
    }
    return true;
}
