import axios, { AxiosResponse } from 'axios';

import { getConfig } from '../config';
import { SingleYouTubeVideoList, YouTubeVideoList } from '../database/videos';

const BASE_URL = getConfig().api.baseUrl;

/**
 * EventsApiContextType provides an API for interacting with Bandsintown Events.
 */
export type VideosApiContextType = {
    /**
     * getYouTubeVideos returns a 200 OK if the artist's YouTube videos are obtained from YouTube
     * or an error otherwise.
     * @returns An AxiosResponse with a list of YouTube videos.
     */
    getYouTubeVideos: () => Promise<AxiosResponse<YouTubeVideoList, any>>;

    getYouTubeVideoById: (
        id: string,
    ) => Promise<AxiosResponse<SingleYouTubeVideoList, any>>;
};

/**
 * getYouTubeVideos returns a 200 OK if the artist's YouTube videos are obtained from YouTube
 * or an error otherwise.
 * @returns An AxiosResponse with a list of YouTube videos.
 */
export function getYouTubeVideos() {
    return axios.get<YouTubeVideoList>(BASE_URL + '/public/videos/list');
}

export function getYouTubeVideoById(id: string) {
    return axios.get<SingleYouTubeVideoList>(BASE_URL + '/public/videos/list', {
        params: { id },
    });
}
