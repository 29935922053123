import axios, { AxiosResponse } from 'axios';

import { getConfig } from '../config';
import { BandsintownEvent } from '../database/events';

const BASE_URL = getConfig().api.baseUrl;

/**
 * EventsApiContextType provides an API for interacting with Bandsintown Events.
 */
export type EventsApiContextType = {
    /**
     * getEvents returns a 200 OK if the artist's events are obtained from Bandsintown
     * or an error otherwise.
     * @returns An AxiosResponse with a list of Bandsintown events.
     */
    getEvents: () => Promise<AxiosResponse<BandsintownEvent[], any>>;
};

/**
 * getEvents returns a 200 OK if the artist's events are obtained from Bandsintown
 * or an error otherwise.
 * @returns An AxiosResponse with a list of Bandsintown events.
 */
export function getEvents() {
    return axios.get<BandsintownEvent[]>(BASE_URL + '/public/events/get');
}
