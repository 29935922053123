import { Button, Container, Divider, Stack, TextField } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';

import { AuthStatus, useAuth } from './Auth';
import { useState } from 'react';
import { RequestSnackbar, useRequest } from '../api/Request';
import { LoadingButton } from '@mui/lab';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import GoogleIcon from '@mui/icons-material/Google';
import LoadingPage from '../loading/LoadingPage';

const SignUpPage = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<Record<string, string>>({});
    const request = useRequest();

    if (auth.status === AuthStatus.Loading) {
        return <LoadingPage />;
    }

    if (auth.status === AuthStatus.Authenticated) {
        return <Navigate to='/' />;
    }

    const onGoogleSignIn = () => {
        auth.socialSignin('Google');
    };

    const onFacebookSignIn = () => {
        auth.socialSignin('Facebook');
    };

    const onSignin = () => {
        const errors: Record<string, string> = {};
        if (name.trim().length === 0) {
            errors.name = 'Name is required';
        }
        if (email.trim().length === 0) {
            errors.email = 'Email is required';
        }
        if (password.length === 0) {
            errors.password = 'Password is required';
        }

        setErrors(errors);
        if (Object.values(errors).length > 0) {
            return;
        }

        request.onStart();
        auth.signup(name.trim(), email.trim(), password)
            .then((result) => {
                navigate('/verify-email', {
                    state: {
                        username: result.user.username,
                        name: name.trim(),
                        email: email.trim(),
                        password,
                    },
                });
            })
            .catch((err) => {
                console.error(err);
                request.onFailure(err);
            });
    };

    return (
        <Container maxWidth='md'>
            <Stack justifyContent='center' alignItems='center' spacing={6}>
                <RequestSnackbar request={request} />
                <Stack spacing={2}>
                    <Button
                        variant='contained'
                        onClick={onGoogleSignIn}
                        startIcon={<GoogleIcon />}
                    >
                        Sign up with Google
                    </Button>
                    <Button
                        variant='contained'
                        onClick={onFacebookSignIn}
                        startIcon={<FacebookOutlinedIcon />}
                    >
                        Sign up with Facebook
                    </Button>
                </Stack>
                <Divider sx={{ width: 1 }}>Or</Divider>
                <Stack width={0.75} spacing={4} alignItems='center'>
                    <TextField
                        fullWidth
                        id='name'
                        label='Name'
                        variant='outlined'
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        error={!!errors.name}
                        helperText={errors.name}
                    />
                    <TextField
                        fullWidth
                        id='email'
                        label='Email'
                        variant='outlined'
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                    <TextField
                        fullWidth
                        id='password'
                        label='Password'
                        type='password'
                        variant='outlined'
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        error={!!errors.password}
                        helperText={errors.password}
                    />
                    <LoadingButton
                        variant='contained'
                        fullWidth
                        sx={{ textTransform: 'none' }}
                        onClick={onSignin}
                        loading={request.isLoading()}
                    >
                        Create Account
                    </LoadingButton>
                    <Button
                        variant='text'
                        sx={{ textTransform: 'none' }}
                        onClick={() => navigate('/signin')}
                    >
                        Already have an account? Sign In
                    </Button>
                </Stack>
            </Stack>
        </Container>
    );
};

export default SignUpPage;
