import { Container, Grid, Skeleton } from '@mui/material';
import { useEffect } from 'react';
import { useApi } from '../api/Api';
import { useRequest } from '../api/Request';
import { Albums } from '../database/music';
import { useTheme } from '@mui/material/styles';

import React from 'react';
import MediaItem from './MediaItem';

export interface MusicTabProps {
    includeAlbumGroup: string;
}

const MusicTab: React.FC<MusicTabProps> = ({ includeAlbumGroup }) => {
    const api = useApi();
    const albumRequest = useRequest<Albums>();
    const theme = useTheme();

    useEffect(() => {
        if (!albumRequest.isSent()) {
            albumRequest.onStart();
            api.getAlbums(includeAlbumGroup)
                .then((response) => {
                    albumRequest.onSuccess(response.data);
                })
                .catch((err) => {
                    console.error(err);
                    albumRequest.onFailure(err);
                });
        }
    }, [api, albumRequest, includeAlbumGroup]);

    return (
        <Container>
            <Grid container justifyContent='center'>
                {albumRequest.data?.items
                    ? albumRequest.data?.items.map((album) => (
                          <MediaItem album={album} key={album.id} />
                      ))
                    : Array.from({ length: 9 }).map((_, index) => (
                          <Grid
                              item
                              key={index}
                              xs={12}
                              sm={6}
                              md={6}
                              lg={4}
                              sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginBottom: '50px',
                              }}
                          >
                              <Skeleton
                                  variant='rounded'
                                  height={300}
                                  width={300}
                                  animation='wave'
                                  sx={{
                                      padding: 0,
                                      borderRadius: '10px',
                                      borderColor: `${theme.palette.primary.main}`,
                                      borderSize: '5px',
                                  }}
                              />
                          </Grid>
                      ))}
            </Grid>
        </Container>
    );
};

export default MusicTab;
