import { Box, Container, Stack, Tab, Tabs } from '@mui/material';
import { AuthStatus, useAuth } from '../auth/Auth';
import { useEffect, useState } from 'react';
import { useApi } from '../api/Api';
import { RequestSnackbar, useRequest } from '../api/Request';
import { Track } from '../database/music';
import { useTheme } from '@mui/material/styles';
import SocialBar from '../socials/SocialBar';
import { TabContext, TabPanel } from '@mui/lab';
import MusicTab from './MusicTab';
import LoadingPage from '../loading/LoadingPage';

const MusicPage = () => {
    const auth = useAuth();
    const theme = useTheme();
    const api = useApi();
    const trackRequest = useRequest<Track[]>();
    const [value, setValue] = useState('all');

    useEffect(() => {
        <script src='https://open.spotify.com/embed/iframe-api/v1' async></script>;
        if (!trackRequest.isSent()) {
            trackRequest.onStart();
            api.getTopTracks()
                .then((response) => {
                    trackRequest.onSuccess(response.data);
                })
                .catch((err) => {
                    console.error(err);
                    trackRequest.onFailure(err);
                });
        }
    }, [api, trackRequest]);

    if (auth.status === AuthStatus.Loading || !trackRequest.isSent()) {
        return <LoadingPage />;
    }

    if (trackRequest.isLoading()) {
        return <LoadingPage />;
    }

    if (!trackRequest.data) {
        return (
            <Stack>
                <RequestSnackbar request={trackRequest} />
            </Stack>
        );
    }

    return (
        <Container maxWidth='lg' sx={{ pt: 6, marginBottom: '100px' }}>
            <div
                style={{
                    display: 'flex',
                    overflowX: 'scroll',
                    borderRadius: '20px',
                    border: `2px solid ${theme.palette.background.default}`,
                    height: '100',
                }}
            >
                {trackRequest.data.map((track) => (
                    <iframe
                        key={track.id}
                        title={track.name}
                        style={{
                            border: 'none',
                            marginRight: '10px',
                        }}
                        src={`https://open.spotify.com/embed/track/${track.id}?utm_source=generator&theme=0`}
                        height='200'
                        allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
                        loading='eager'
                    ></iframe>
                ))}
            </div>
            <Stack spacing={4}>
                <Box sx={{ width: '100%', typography: 'body1', mt: 5 }}>
                    <TabContext value={value}>
                        <Box
                            sx={{
                                width: '100%',
                                typography: 'body1',
                                mt: 5,
                                justifyContent: 'center',
                                display: 'flex',
                            }}
                        >
                            <Tabs
                                value={value}
                                onChange={(_, t) => setValue(t)}
                                aria-label='profile tabs'
                                textColor='inherit'
                                variant='scrollable'
                            >
                                <Tab label='All Music' value='all' />
                                <Tab label='Albums' value='album' />
                                <Tab label='Singles' value='single' />
                                <Tab label='Appears On' value='appears_on' />
                            </Tabs>
                        </Box>
                        <TabPanel value='all' sx={{ pt: 6, pb: 0 }}>
                            <MusicTab
                                includeAlbumGroup={'album,single,appears_on,compilation'}
                            />
                        </TabPanel>
                        <TabPanel value='album'>
                            <MusicTab includeAlbumGroup={value} />
                        </TabPanel>
                        <TabPanel value='single'>
                            <MusicTab includeAlbumGroup={value} />
                        </TabPanel>
                        <TabPanel value='appears_on'>
                            <MusicTab includeAlbumGroup={value} />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Stack>
            <SocialBar />
        </Container>
    );
};

export default MusicPage;
