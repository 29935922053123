import { createContext, ReactNode, useContext, useMemo } from 'react';

import { useAuth } from '../auth/Auth';

import { User } from '../database/user';

import {
    UserApiContextType,
    getUser,
    getUserPublic,
    checkUserAccess,
    updateUser,
    listUsersPublic,
    deleteUser,
    deleteUserPublic,
    userGeneratePresignedUrl,
    uploadProfilePicToS3,
} from './userApi';

import { EventsApiContextType, getEvents } from './eventsApi';

import { VideosApiContextType, getYouTubeVideos, getYouTubeVideoById } from './videosApi';

import {
    CommunityApiContextType,
    CommunityPostRequest,
    createCommunityPost,
    listCommunityPosts,
    listCommunityPostsByOwner,
    createComment,
    deletePost,
    communityPostGeneratePresignedUrl,
    uploadMediaToS3,
} from './communityApi';

import { MusicApiContextType, getAlbums, getTopTracks, getTrackById } from './musicApi';
import { CommunityPost } from '../database/community';
import {
    PaymentApiContextType,
    createPaymentAccount,
    getPaymentAccount,
    paymentAccountLogin,
    setUpFuturePayment,
    getUserPaymentInfo,
} from './paymentApi';
import {
    answerQuestion,
    AnswerQuestionRequest,
    createQuestion,
    CreateQuestionRequest,
    deleteQuestion,
    listMostRecentQuestions,
    listQuestionsByOwner,
    listQuestionsByResponseType,
    listQuestionsByStatus,
    theSourceGeneratePresignedUrl,
    TheSourceApiContextType,
    uploadVideoToS3,
    MediaUploadRequest,
} from './theSourceApi';
import { Question } from '../database/theSource';

/**
 * ApiContextType defines the interface of the API as available through ApiProvider.
 */
type ApiContextType = UserApiContextType &
    CommunityApiContextType &
    EventsApiContextType &
    MusicApiContextType &
    VideosApiContextType &
    PaymentApiContextType &
    TheSourceApiContextType;

const ApiContext = createContext<ApiContextType>(null!);

/**
 * @returns The current ApiContext value.
 */
export function useApi() {
    return useContext(ApiContext);
}

/**
 * ApiProvider provides access to API calls. It implements the ApiContextType interface.
 * ApiProvider must be a child of AuthProvider.
 * @param param0 React props. The only used prop is children.
 * @returns An ApiContext.Provider wrapping the provided children.
 */
export function ApiProvider({ children }: { children: ReactNode }) {
    const auth = useAuth();
    const idToken = auth.user?.cognitoUser?.session?.idToken.jwtToken ?? '';
    // const accessToken = auth.user?.cognitoUser?.session.accessToken.jwtToken ?? '';

    const value = useMemo(() => {
        return {
            checkUserAccess: () => checkUserAccess(idToken),
            getUser: () => getUser(idToken),
            getUserPublic: (username: string) => getUserPublic(username),
            updateUser: (update: Partial<User>) =>
                updateUser(idToken, update, auth.updateUser),
            getEvents: () => getEvents(),
            getAlbums: (includeAlbumGroup: string) => getAlbums(includeAlbumGroup),
            getTopTracks: () => getTopTracks(),
            getTrackById: (id: string) => getTrackById(id),
            getYouTubeVideos: () => getYouTubeVideos(),
            getYouTubeVideoById: (id: string) => getYouTubeVideoById(id),
            createCommunityPost: (req: CommunityPostRequest) =>
                createCommunityPost(idToken, req),
            deletePost: (post: CommunityPost) => deletePost(idToken, post),
            listCommunityPosts: (startKey?: string) =>
                listCommunityPosts(idToken, startKey),
            listCommunityPostsByOwner: (startKey?: string) =>
                listCommunityPostsByOwner(idToken, startKey),
            createComment: (postOwner: string, postCreatedAt: string, content: string) =>
                createComment(idToken, postOwner, postCreatedAt, content),
            listUsersPublic: () => listUsersPublic(),
            deleteUser: () => deleteUser(idToken),
            deleteUserPublic: (username: string) => deleteUserPublic(idToken, username),
            createPaymentAccount: () => createPaymentAccount(idToken),
            getPaymentAccount: () => getPaymentAccount(idToken),
            paymentAccountLogin: () => paymentAccountLogin(idToken),
            setUpFuturePayment: (req: CreateQuestionRequest) => setUpFuturePayment(idToken, req),
            createQuestion: (req: CreateQuestionRequest) => createQuestion(idToken, req),
            listMostRecentQuestions: (startKey?: string) =>
                listMostRecentQuestions(idToken, startKey),
            answerQuestion: (req: AnswerQuestionRequest) => answerQuestion(idToken, req),
            theSourceGeneratePresignedUrl: (question: Question, contentType: string) =>
                theSourceGeneratePresignedUrl(idToken, question, contentType),
            uploadVideoToS3: (req: MediaUploadRequest) => uploadVideoToS3(req),
            listQuestionsByOwner: (startKey?: string) =>
                listQuestionsByOwner(idToken, startKey),
            listQuestionsByResponseType: (responseType: string, startKey?: string) =>
                listQuestionsByResponseType(responseType, idToken, startKey),
            listQuestionsByStatus: (status: string, scanForwardIndex: boolean, startKey?: string) => listQuestionsByStatus(status, scanForwardIndex, idToken, startKey,),
            deleteQuestion: (question: Question) => deleteQuestion(idToken, question),
            getUserPaymentInfo: () => getUserPaymentInfo(idToken),
            communityPostGeneratePresignedUrl: (post: CommunityPost, contentType: string) => communityPostGeneratePresignedUrl(idToken, post, contentType),
            uploadMediaToS3: (req: MediaUploadRequest) => uploadMediaToS3(req),
            userGeneratePresignedUrl: (contentType: string) => userGeneratePresignedUrl(idToken, contentType),
            uploadProfilePicToS3: (req: MediaUploadRequest) => uploadProfilePicToS3(req),
        };
    }, [idToken, auth.updateUser]);

    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}
