import { Typography } from '@mui/material';

interface DisplayNameProps {
    displayName: string;
}

const DisplayName: React.FC<DisplayNameProps> = ({ displayName }) => {
    return <Typography variant='h4'>{displayName}</Typography>;
};

export default DisplayName;
