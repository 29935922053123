import Toolbar from '@mui/material/Toolbar';
import { SocialIcon } from 'react-social-icons';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { getConfig } from '../config';

const artist = getConfig().artist;

const socialMediaLinks = [
    {
        name: 'SoundCloud',
        url: `https://www.soundcloud.com/${artist.socialsName}`,
    },
    {
        name: 'Instagram',
        url: `https://www.instagram.com/${artist.socialsName}`,
    },
    {
        name: 'Spotify',
        url: `https://open.spotify.com/artist/${artist.spotifyId}`,
    },
    {
        name: 'X',
        url: `https://x.com/${artist.socialsName}`,
    },
    {
        name: 'Bandsintown',
        url: `https://www.bandsintown.com/a/${artist.bandsInTownId}`,
    },
    {
        name: 'YouTube',
        url: `https://www.youtube.com/channel/${artist.youTubeId}`,
    },
];

const SocialBar = () => {
    const theme = useTheme();

    return (
        <Toolbar
            disableGutters
            sx={{
                justifyContent: 'center',
            }}
        >
            <Stack spacing={1} direction='row'>
                {socialMediaLinks.map((link, index) => (
                    <SocialIcon
                        key={index}
                        url={link.url}
                        target='_blank'
                        fgColor={theme.palette.secondary.main}
                        bgColor={theme.palette.primary.main}
                        aria-label={link.name}
                        style={{ height: 40, width: 40 }}
                    />
                ))}
            </Stack>
        </Toolbar>
    );
};

export default SocialBar;
