import { Button, Stack, styled, Theme, Typography, useMediaQuery } from "@mui/material";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { useMemo, useState } from "react";
import Icon, { icons } from "../components/Icon";
import MultipleSelectChip from "../components/MultipleSelectChip";
import { TabValue } from "./TheSourceTab";
import { QuestionStatus } from "./constants/QuestionStatus";
import { ResponseType } from "./constants/ResponseType";

export const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    '&:before': {
        display: 'none',
    },
}));

export const AccordionSummary = styled(
    ({
        forceExpansion,
        ...props
    }: AccordionSummaryProps & { forceExpansion: boolean }) => (
        <MuiAccordionSummary
            expandIcon={
                !forceExpansion && (
                    <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: (theme) => theme.palette.secondary.main }} />
                )
            }
            {...props}
        />
    ),
)(({ theme }) => ({
    paddingLeft: 0,
    border: 0,
    minHeight: 0,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        marginTop: 0,
        marginBottom: 0,
    },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    padding: 0,
    paddingLeft: theme.spacing(1),
}));

export interface Filters {
    responseTypeFilters: ResponseType[];
    setResponseTypeFilters: (v: ResponseType[]) => void;

    statusFilters: QuestionStatus[];
    setStatusFilters: (v: QuestionStatus[]) => void;
}

export function useFilters(): Filters {
    const [responseTypeFilters, setResponseTypeFilters] = useState(Object.values(ResponseType))

    const [statusFilters, setStatusFilters] = useState(Object.values(QuestionStatus))

    const result = useMemo(
        () => ({
            responseTypeFilters,
            setResponseTypeFilters,
            statusFilters,
            setStatusFilters,
        }),
        [
            responseTypeFilters,
            setResponseTypeFilters,
            statusFilters,
            setStatusFilters,
        ],
    );

    return result;
}

interface TheSourceFiltersProps {
    filters: Filters;
    tabValue: TabValue;
}

export const TheSourceFilters: React.FC<TheSourceFiltersProps> = ({ filters, tabValue }) => {
    const [expanded, setExpanded] = useState<string | boolean>(false);
    const forceExpansion = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const onChangeResponseTypeFilters = (statuses: string[]) => {
        filters.setResponseTypeFilters(statuses as ResponseType[]);
    };

    const onChangeStatusFilters = (statuses: string[]) => {
        filters.setStatusFilters(statuses as QuestionStatus[]);
    };

    const handleChange =
        (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
            if (!forceExpansion) {
                setExpanded(newExpanded ? panel : false);
            }
        };

    const onReset = () => {
        filters.setResponseTypeFilters(Object.values(ResponseType))
        filters.setStatusFilters(Object.values(QuestionStatus))
    }
    return (
        <Accordion
            expanded={forceExpansion || expanded === 'theSource'}
            onChange={handleChange('theSource')}
        >
            {!forceExpansion && (
                <AccordionSummary
                    forceExpansion={forceExpansion}
                >
                    <Typography variant='h6'>
                        Filters
                    </Typography>
                </AccordionSummary>
            )}
            <AccordionDetails sx={{ border: 'none' }}>
                <Stack sx={{ mt: 2, pb: 2 }} spacing={3}>
                    {!Object.values(ResponseType).includes(tabValue as ResponseType) &&
                        <Stack>
                            <Typography variant='h6'>
                                <Icon
                                    name='responseTypes'
                                    color='primary'
                                    sx={{
                                        marginRight: '0.4rem',
                                        verticalAlign: 'middle',
                                    }}
                                    fontSize='medium'
                                />
                                Response Types
                            </Typography>
                            <MultipleSelectChip
                                selected={filters.responseTypeFilters}
                                setSelected={onChangeResponseTypeFilters}
                                options={
                                    Object.values(ResponseType).map((opt) => ({
                                        value: opt,
                                        label: opt,
                                        icon: <Icon name={opt as keyof typeof icons} color='primary' />,
                                    }))}
                                error={filters.responseTypeFilters.length === 0}
                            />
                        </Stack>
                    }
                    {!Object.values(QuestionStatus).includes(tabValue as QuestionStatus) &&
                        <Stack>
                            <Typography variant='h6'>
                                <Icon
                                    name='statuses'
                                    color='primary'
                                    sx={{
                                        marginRight: '0.4rem',
                                        verticalAlign: 'middle',
                                    }}
                                    fontSize='medium'
                                />
                                Statuses
                            </Typography>
                            <MultipleSelectChip
                                selected={filters.statusFilters}
                                setSelected={onChangeStatusFilters}
                                options={
                                    Object.values(QuestionStatus).map((opt) => ({
                                        value: opt,
                                        label: opt,
                                        icon: <Icon name={opt as keyof typeof icons} color='primary' />,
                                    }))}
                                error={filters.statusFilters.length === 0}

                            />
                        </Stack>
                    }
                    <Button
                        variant='outlined'
                        onClick={onReset}
                        sx={{ alignSelf: 'start' }}
                        startIcon={<Icon name='reset' />}
                    >
                        Reset Filters
                    </Button>
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
};