import { Box, Container, Stack, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { ListCommunityPostsResponse } from '../api/communityApi';
import { CommunityPost } from '../database/community';
import CommunityPostSubmissionForm from './post/PostSubmissionForm';
import SocialBar from '../socials/SocialBar';
import { TabContext, TabPanel } from '@mui/lab';
import CommunityPostTab from './post/PostTab';

const CommunityPage = () => {
    const [data, setData] = useState<ListCommunityPostsResponse>();
    // const [lastStartKey, setLastStartKey] = useState('');
    const [value, setValue] = useState('recent');

    const onCreatePost = (post: CommunityPost) => {
        const newData = data?.posts || [];
        setData({
            posts: [post, ...newData],
            lastEvaluatedKey: data?.lastEvaluatedKey || '',
        });
    };

    return (
        <Container maxWidth='lg' sx={{ mb: '100px' }}>
            <Stack spacing={4}>
                <CommunityPostSubmissionForm onSubmit={(e) => onCreatePost(e)} />
                <Box sx={{ width: '100%', typography: 'body1', mt: 5 }}>
                    <TabContext value={value}>
                        <Tabs
                            value={value}
                            onChange={(_, t) => setValue(t)}
                            aria-label='post tabs'
                            textColor='inherit'
                            centered
                        >
                            <Tab label='Most Recent' value='recent' />
                            <Tab label='My Posts' value='my' />
                        </Tabs>
                        <TabPanel value='recent' sx={{ px: { xs: 0, sm: 3 } }}>
                            <CommunityPostTab
                                query={value}
                                data={data}
                                setData={setData}
                                // setLastStartKey={setLastStartKey}
                            />
                        </TabPanel>
                        <TabPanel value='my' sx={{ px: { xs: 0, sm: 3 } }}>
                            <CommunityPostTab
                                query={value}
                                data={data}
                                setData={setData}
                                // setLastStartKey={setLastStartKey}
                            />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Stack>
            <SocialBar />
        </Container>
    );
};

export default CommunityPage;
