import { Button, Stack, TextField, Typography } from '@mui/material';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { useState } from 'react';
import { useApi } from '../api/Api';
import { useRequest } from '../api/Request';
import { Question } from '../database/theSource';
import { ResponseType } from './constants/ResponseType';
import { useAuth } from '../auth/Auth';
import { QuestionStatus } from './constants/QuestionStatus';
import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

interface AnswerPromptCardProps {
    question: Question;
    onSave: (question: Question) => void;
}

const AnswerPromptCard: React.FC<AnswerPromptCardProps> = ({ question, onSave }) => {
    const auth = useAuth();
    const user = auth.user!
    const [answerContent, setAnswerContent] = useState('');
    const [presignedUrl, setPresignedUrl] = useState<string>('');
    const [fileToUpload, setFileToUpload] = useState<File>();
    const [previewUrl, setPreviewUrl] = useState<string>('');
    const api = useApi();
    const answerQuestionRequest = useRequest<Question>();

    const onUploadVideo = (
        event: React.ChangeEvent<HTMLInputElement>,
        question: Question,
    ) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (!file) return;
        // Clear previewUrl if previously uploaded a video
        if (previewUrl) {
            URL.revokeObjectURL(previewUrl);
            setPreviewUrl('');
        }
        api.theSourceGeneratePresignedUrl(question, file.type).then((resp) => {
            setPresignedUrl(resp.data.presignedUrl);
            setFileToUpload(file);
            const url = URL.createObjectURL(file);
            setPreviewUrl(url);
            setAnswerContent(resp.data.key);
        });
    };

    const isAnwerSubmissionDisabled = () => {
        switch (question.responseType) {
            case ResponseType.Text: {
                return answerContent.trim().length === 0
            }
            case ResponseType.Video: {
                return !presignedUrl
            }
            default: {
                return true; // Should only reach this if question has invalid response type
            }
        }
    }

    const resetVideo = () => {
        URL.revokeObjectURL(previewUrl);
        setPreviewUrl('')
        setFileToUpload(undefined)
        setPresignedUrl('')
    }
    const onAnswer = (question: Question) => {
        if (question.responseType === ResponseType.Video) {
            if (!fileToUpload) {
                // Handle if video is not available to upload
            } else {
                answerQuestionRequest.onStart();
                api.uploadVideoToS3({
                    presignedUrl: presignedUrl,
                    mediaContent: fileToUpload,
                    contentType: fileToUpload.type,
                })
                    .then(() => {
                        api.answerQuestion({
                            questionOwner: question.owner,
                            questionCreatedAt: question.createdAt,
                            content: answerContent,
                            responseType: ResponseType.Video,
                        })
                            .then((resp) => {
                                resp.data.presignedUrl = previewUrl
                                onSave(resp.data)
                                console.log('answerQuestion: ', resp);
                                answerQuestionRequest.onSuccess();
                            })
                            .catch((err: unknown) => {
                                console.error('answerQuestion error: ', err);
                                answerQuestionRequest.onFailure();
                            });
                    })
                    .catch((err: unknown) => {
                        console.error('Unable to upload video: ', err);
                        answerQuestionRequest.onFailure();
                    });;
            }
        } else {
            answerQuestionRequest.onStart();
            api.answerQuestion({
                questionOwner: question.owner,
                questionCreatedAt: question.createdAt,
                content: answerContent,
                responseType: ResponseType.Text,
            })
                .then((resp) => {
                    onSave(resp.data)
                    console.log('answerQuestion: ', resp);
                    answerQuestionRequest.onSuccess();
                })
                .catch((err: unknown) => {
                    console.error('answerQuestion error: ', err);
                    answerQuestionRequest.onFailure();
                });
        }
    };

    const renderAnswerPromptContent = () => {
        switch (question.responseType) {
            case ResponseType.Text: {
                return (
                    <TextField
                        label='Question Response'
                        placeholder='Type Response Here'
                        value={answerContent}
                        onChange={(e) => setAnswerContent(e.target.value)}
                        multiline
                        minRows={1}
                        maxRows={7}
                        fullWidth={true}
                    />
                )
            }
            case ResponseType.Video: {
                return (
                    <Stack spacing={3}>
                        {previewUrl && (
                            <video controls style={{ width: '100%', height: 'auto' }}>
                                <source src={previewUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                        <Stack spacing={{ md: 2 }} direction={{ md: 'column', lg: 'row' }} justifyContent={{ lg: 'space-between' }}>
                            <Button
                                component='label'
                                variant='contained'
                                startIcon={<VideoLibraryIcon />}
                            >
                                {presignedUrl ? "Choose Different Video" : "Upload Video"}
                                <input
                                    type='file'
                                    accept='video/*'
                                    hidden
                                    onChange={(e) => onUploadVideo(e, question)}
                                />
                            </Button>
                            {previewUrl && (
                                <Button
                                    component='label'
                                    variant='contained'
                                    startIcon={<Delete />}
                                    onClick={() => resetVideo()}
                                >
                                    Remove Video
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                )
            }
            default: {
                return (
                    <Typography>
                        Question not available to answer
                    </Typography>
                )
            }
        }
    }
    if (!user.isArtist || question.status === QuestionStatus.Answered) {
        return null
    } else {
        return (
            <Stack spacing={3}>
                {renderAnswerPromptContent()}
                <LoadingButton
                    variant='contained'
                    onClick={() => onAnswer(question)}
                    loading={answerQuestionRequest.isLoading()}
                    disabled={isAnwerSubmissionDisabled()}
                    sx={{
                        opacity: isAnwerSubmissionDisabled() ? 0.5 : 1
                    }}
                >
                    Answer
                </LoadingButton>
            </Stack>
        );
    }

};

export default AnswerPromptCard;
