import axios, { AxiosResponse } from 'axios';

import { getConfig } from '../config';
import { PaymentMethod, StripeAccount } from '../database/payment';
import { CreateQuestionRequest } from './theSourceApi';

const BASE_URL = getConfig().api.baseUrl;
export interface PaymentApiContextType {
    /**
     * Creates a checkout session.
     * @returns A subscription checkout session URL.
     */
    setUpFuturePayment: (req: CreateQuestionRequest) => Promise<AxiosResponse<StripeUrlResponse>>;

    /**
     * Creates a Stripe account for the current user.
     * @returns A Stripe account link URL.
     */
    createPaymentAccount: () => Promise<AxiosResponse<StripeUrlResponse>>;

    getPaymentAccount: () => Promise<AxiosResponse<StripeAccount>>;

    paymentAccountLogin: () => Promise<AxiosResponse<string>>;

    getUserPaymentInfo: () => Promise<AxiosResponse<PaymentMethod>>;
}

/** A common response to Stripe requests. Contains a URL to Stripe workflows. */
export interface StripeUrlResponse {
    /** The Stripe URL. */
    url: string;
}

export function setUpFuturePayment(idToken: string, req: CreateQuestionRequest) {
    return axios.post<StripeUrlResponse>(
        `${BASE_URL}/payment/setup`,
        req,
        {
            headers: {
                Authorization: 'Bearer ' + idToken,
            },
        },
    );
}

/**
 * Creates a Stripe account for the current user.
 * @param idToken The id token of the current signed-in user.
 * @returns A Stripe account link URL.
 */
export function createPaymentAccount(idToken: string) {
    return axios.put<StripeUrlResponse>(
        `${BASE_URL}/payment/account`,
        {},
        {
            headers: {
                Authorization: 'Bearer ' + idToken,
            },
        },
    );
}

/**
 * Gets the Stripe account for the current user.
 * @param idToken The id token of the current signed-in user.
 * @returns A Stripe account.
 */
export function getPaymentAccount(idToken: string) {
    return axios.get<StripeAccount>(`${BASE_URL}/payment/account`, {
        headers: {
            Authorization: 'Bearer ' + idToken,
        },
    });
}

export function paymentAccountLogin(idToken: string) {
    return axios.get<string>(`${BASE_URL}/payment/login`, {
        headers: {
            Authorization: 'Bearer ' + idToken,
        },
    });
}

export function getUserPaymentInfo(idToken: string) {
    return axios.get<PaymentMethod>(`${BASE_URL}/payment/method`, {
        headers: {
            Authorization: 'Bearer ' + idToken,
        }
    })
}
