import { Chip, Tooltip } from '@mui/material';
import Icon from '../../components/Icon';
import { User } from '../../database/user';

interface QuestionCostChipProps {
    cost: number;
    user: User;
}

const displayQuestionCost = (user: User, baseCost: number): number => {
    if (user.isArtist) {
        return baseCost * 0.8 - (Math.round(baseCost * 0.029 + 0.30) / 100);
    } else {
        return baseCost;
    }
}
const QuestionCostChip: React.FC<QuestionCostChipProps> = ({ cost, user }) => {
    return (
        <Tooltip title={`Money for answering question: $${cost}`}>
            <Chip
                icon={<Icon name={'cost'} />}
                label={'$'+displayQuestionCost(user, cost)}
                variant='outlined'
                color='success'
            />
        </Tooltip>
    );
};

export default QuestionCostChip;
